import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { typeAhead } from '../../../store/actions/post.actions'
import '../../Field/index.css'

class TypeAhead extends Component {
    state = {
        multiple: false,
        inputValue: '',
        isValid: false,
    };
    componentDidMount() {
        this.setState({ inputValue: this.props.default ? this.props.default : '' }, () => {
            this.props.typeAhead(this.state.inputValue)
        })
    }
    handleInputChange = (input, e) => {
        this.setState({ inputValue: input }, () => {
            this.props.typeAhead(this.state.inputValue)
            this.state.inputValue.length !== 0 ? this.setState({ isValid: true }) : this.setState({ isValid: false })
        })
    }
    render() {
        var inputValue = document.getElementsByClassName("rbt-input");
        let border_category = document.getElementsByClassName("border-category");
        var onFocus = () => {
            border_category[0].classList.add("type-border-div-before");
            border_category[0].classList.remove("type-border-div-after");
        };
        var onclick = () => {
            border_category[0].classList.add("type-border-div-before");
            border_category[0].classList.remove("type-border-div-after");
        }
        var onBlur = () => {
            if (!inputValue[0].value) {
                border_category[0].classList.remove("type-border-div-before");
                border_category[0].classList.add("type-border-div-after");
            }
        };
        for (var i = 0; i < inputValue.length; i++) {
            if (inputValue[0].value) {
                border_category[0].classList.add("type-border-div-before");
                border_category[0].classList.remove("type-border-div-after");
            }
            border_category[i].addEventListener('click', onclick, false);
            inputValue[i].addEventListener('focus', onFocus, false);
            inputValue[i].addEventListener('blur', onBlur, false);
        }
        return (
            <div className="d-flex flex-column input-border">
                <div className="type-border-div-after border-category"></div>
                <div className="m-2">
                    <Typeahead
                        id="my-typeahead-id"
                        clearButton
                        allowNew
                        newSelectionPrefix="Select new keyword: "
                        labelKey="tag"
                        defaultSelected={[this.props.typeahead ? this.props.typeahead : this.props.default ? this.props.default : this.state.inputValue]}
                        options={this.props.tags}
                        onInputChange={this.handleInputChange}
                        onChange={(selected) => {
                            if (selected.length) {
                                this.setState({ inputValue: selected[0].tag ? selected[0].tag : selected[0] }, () => {
                                    this.props.typeAhead(this.state.inputValue);
                                    this.state.inputValue.length !== 0 ? this.setState({ isValid: true }) : this.setState({ isValid: false })
                                });
                            }
                        }}
                        selected={this.state.selected}
                    // placeholder="Choose a special keyword"
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        typeahead: state.postReducer.typeahead,
        tags: state.postReducer.items
    }
};
const mapDispatchToProps = {
    typeAhead
}
export default connect(mapStateToProps, mapDispatchToProps)(TypeAhead);
