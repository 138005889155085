import React from 'react';
import Modal from "react-bootstrap/Modal";
import './modal.css'

export const Bmodal = (props) => {
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="card shadow position-relative">
                <div className="face-div position-absolute">
                    <img className="face" src={require('../../../assets/common/face.svg')} alt="oops!" />
                </div>
                <div className="position absolute">
                    <div className="text-center msg">OOPS !</div>
                    <div className="msg-contents">You are out of points ! To continue ensure you have sufficent points</div>
                </div>
                <div className="modal-buttons mt-5 mb-5 text-center">
                    <button className="btn btn-success mr-4 buy">BUY POINTS</button>
                    <button className="btn cancel" onClick={props.onHide}>Cancel</button>
                </div>
            </div>
        </Modal>
    );
};
