import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { connect } from 'react-redux';
import { setContent } from '../../../store/actions/post.actions'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class editor extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        postvalue: '',
    }
    componentDidMount() {
        // eslint-disable-next-line no-unused-expressions
        this.props.default ?
            this.onLoadData(``) : null
    }
    onLoadData() {
        const contentState = convertFromRaw(JSON.parse(this.props.default ? this.props.default : null));
        this.setState({
            editorState: EditorState.createWithContent(contentState)
        })
    }
    onEditorStateChange = (editorState) => {
        this.setState({ editorState });
        const rawContent = convertToRaw(this.state.editorState.getCurrentContent());
        this.setState({ postvalue: JSON.stringify(rawContent) }, () => {
            this.props.setContent(this.state.postvalue)
        })
    };
    render() {
        const { editorState } = this.state;
        return (
            <React.Fragment>
                <Editor
                    editorState={editorState}
                    editorClassName="editor-class form-control border"
                    onEditorStateChange={this.onEditorStateChange}
                    editorStyle={{
                        minHeight: "18rem",
                    }} />
            </React.Fragment>

        );
    }
}
const mapStateToProps = state => {
    return {
        content: state.postReducer.postContent
    }
};
const mapDispatchToProps = {
    setContent
}

export default connect(mapStateToProps, mapDispatchToProps)(editor);