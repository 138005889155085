import { LOGIN, USER_AVAILABLE, POINTS } from "../actions/types";

const initialState = {
    // auth session
    token: '',
    currentUser: null,
    points: 0,
    userAvailable: false
}
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                currentUser: action.payload
            }
        case USER_AVAILABLE:
            return {
                ...state,
                userAvailable: action.payload
            }
        case POINTS:
            return {
                ...state,
                points: action.payload
            }
        default:
            return state
    }
}
export default authReducer;