import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PostLoader } from '../../contentSkeletons/skeleton'
import { discuss, discussloader } from '../../../../store/actions/post.actions'
import Card from './discussCard'
import './discussion.css'
class Discussions extends Component {
    _isMounted = false;
    state = {
        option: null,
        posts: null,
        initialPage: 1,
        loading: false,
        limit: 10,
        pageNo: 0,
        nextCall: false,
        disabled: false
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({ disabled: true })
        if (this.state.option === null) {
            this.timer = setTimeout(() => {
                let data = { limit: 10, pageNo: 0, option: this.state.option !== null ? this.state.option : 'recent', user: this.props.user ? this.props.user : null }
                this.props.discuss(data, (err) => {
                    if (!err && this._isMounted) {
                        this.setState({ pageNo: this.state.pageNo + 1, loading: false, nextCall: true, option: 'recent', disabled: false });
                    }
                })
            }, 1000)
        }
    }
    onSelectHandler = (option) => {
        this.setState({ disabled: true })
        this.props.discussloader();
        this.setState({ option: option, pageNo: 0 })
        let data = { limit: 10, pageNo: 0, option: option, user: this.props.user ? this.props.user : null }
        this.props.discuss(data, (err) => {
            if (!err && this._isMounted) {
                this.setState({ pageNo: this.state.pageNo + 1, loading: false, nextCall: true, disabled: false });
            }
        })
        this.setState({ option: option })
    }
    loadMore = () => {
        this.setState({ nextCall: false, loading: true, disabled: true })
        let data = { limit: 10, pageNo: this.state.pageNo, option: this.state.option, user: this.props.user ? this.props.user : null }
        this.props.discuss(data, (err) => {
            if (!err && this._isMounted) {
                this.setState({ pageNo: this.state.pageNo + 1, loading: false, nextCall: true, disabled: false });
            } else {
                this.setState({ loading: false, disabled: true })
            }
        })
    }
    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.timer)
    }
    render() {
        const { auth, discussPost, discussCommentCount } = this.props;

        return (
            <div className="container mt-5 pb-5 px-4 d-flex flex-column px-md-4" style={{ paddingLeft: "25px", paddingRight: "25px" }}>
                <div className="text-left">
                    <div className="discussions">
                        Discussions
                </div>
                </div>
                <div className="d-flex flex-row border-bottom mb-3 discuss-nav">
                    <div className={["option py-lg-4 mr-lg-5", this.state.option === 'recent' || this.state.option === null ? 'active' : null].join(' ')} onClick={() => this.onSelectHandler('recent')}>Recent</div>
                    <div className={["option py-lg-4 mr-lg-5", this.state.option === 'recommented' ? 'active' : null].join(' ')} onClick={() => this.onSelectHandler('recommented')} >Recommended</div>
                    <div className={["option py-lg-4", this.state.option === 'most-upvoted' ? 'active' : null].join(' ')} onClick={() => this.onSelectHandler('most-upvoted')} >Most Upvoted</div>
                </div>
                <div>
                    {auth && this._isMounted
                        ?
                        <Card posts={discussPost} count={discussCommentCount} />
                        : <PostLoader />
                    }
                    <button className="btn-more" disabled={this.state.disabled} onClick={this.loadMore}>
                        {this.state.loading ? <div className="px-4 pb-1">...</div> : 'View More'}
                    </button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.postReducer.discuss_fetched,
        discussPost: state.postReducer.discuss,
        user: state.authReducer.currentUser && state.authReducer.currentUser._id,
        discussCommentCount: state.postReducer.discussPostCommentCount
    }
};

const mapDispatchToProps = {
    discuss,
    discussloader
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Discussions));
