import React, { Component } from 'react'
import './log.css'
class LogMessage extends Component {
    handler = (id) => {
        this.props.handler(id, 'profile');
    }
    render() {
        const { type, content_user, action_user, content_type} = this.props
        return (
            type === 'signup' ? <div className="msg-text"> Welcome <strong>{action_user.u_userName}</strong> have been awarded 100 points for registering with us</div>
                : type === 'article_post' ? <div className="msg-text"> Congrats! You created a Article</div>
                    : type === 'photo_post' ? <div className="msg-text"> 'Congrats! You Uploded a Photo'</div>
                        : type === 'upvote' ? <div className="msg-text"> You recently Upvote on <strong className="profile-direct" onClick={() => this.handler(content_user._id)}>{content_user.u_userName}</strong>'s {content_type}</div>
                            : type === 'downvote' ? <div className="msg-text"> You recently Downvote on <strong className="profile-direct" onClick={() => this.handler(content_user._id)}>{content_user.u_userName}</strong>'s {content_type}</div>
                                : type === 'article_comment' ? <div className="msg-text"> You commented on <strong className="profile-direct" onClick={() => this.handler(content_user._id)}>{content_user.u_userName}</strong>'s {content_type}</div>
                                    : type === 'photo_comment' ? <div className="msg-text"> You commented on <strong className="profile-direct" onClick={() => this.handler(content_user._id)}>{content_user.u_userName}</strong>'s {content_type}</div>
                                        : type === 'comment_upvote' ? <div className="msg-text"> You recently Upvote on <strong className="profile-direct" onClick={() => this.handler(content_user._id)}>{content_user.u_userName}</strong>'s{content_type}'s comment</div>
                                            : type === 'comment_downvote' ? <div className="msg-text"> You recently Downvote on <strong className="profile-direct" onClick={() => this.handler(content_user._id)}>{content_user.u_userName}</strong>'s {content_type}'s comment</div>
                                                : type === 'get_post_upvote' ? <div className="msg-text"> <strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> upvoted on your {content_type}</div>
                                                    : type === 'get_post_downvote' ? <div className="msg-text"> <strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> downvoted on your {content_type}</div>
                                                        : type === 'get_photo_upvote' ? <div className="msg-text"> <strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> upvoted on your {content_type}</div>
                                                            : type === 'get_photo_downvote' ? <div className="msg-text"> <strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> downvoted on your {content_type}</div>
                                                                : type === 'get_comment_upvote' ? <div className="msg-text"> <strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> upvoted on your comment</div>
                                                                    : type === 'get_comment_downvote' ? <div className="msg-text"> <strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> upvoted on your comment</div>
                                                                        : type === 'cancel_upvote' ? <div className="msg-text">You cancelled upvote of <strong className="profile-direct" onClick={() => this.handler(content_user._id)}>{content_user.u_userName}</strong>'s {content_type} </div>
                                                                            : type === 'cancel_downvote' ? <div className="msg-text">You cancelled downvote of <strong className="profile-direct" onClick={() => this.handler(content_user._id)}>{content_user.u_userName}</strong>'s {content_type} </div>
                                                                                : type === 'cancel_get_post_upvote' ? <div className="msg-text"><strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> cancelled {content_type}'s upvote</div>
                                                                                    : type === 'cancel_get_post_downvote' ? <div className="msg-text"><strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> cancelled {content_type}'s downvote</div>
                                                                                        : type === 'cancel_get_comment_upvote' ? <div className="msg-text"><strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> cancelled upvote of your comment</div>
                                                                                            : type === 'cancel_get_comment_downvote' ? <div className="msg-text"><strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName}</strong> cancelled downvote of your comment</div>
                                                                                                : type === 'cancel_comment_upvote' ? <div className="msg-text">You cancelled <strong className="profile-direct" onClick={() => this.handler} >{content_user.u_userName}'s upvote of comment</strong> </div>
                                                                                                    : type === 'cancel_comment_downvote' ? <div className="msg-text">You cancelled <strong className="profile-direct" onClick={() => this.handler(content_user._id)}>{content_user.u_userName}'s downvote of comment</strong> </div>
                                                                                                        : type === 'cancel_get_photo_upvote' ? <div className="msg-text"> <strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName} cancelled upvote of your {content_type} </strong> </div>
                                                                                                            : type === 'cancel_get_photo_downvote' ? <div className="msg-text"> <strong className="profile-direct" onClick={() => this.handler(action_user._id)}>{action_user.u_userName} cancelled upvote of your {content_type}</strong> </div>
                                                                                                                : <div></div>
        )
    }
}
export default LogMessage