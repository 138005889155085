import io from 'socket.io-client'
let baseURL;
if (process.env.NODE_ENV === "development") {
    baseURL = 'http://localhost:4000'
}
else {
    baseURL = 'https://api.fotogramam.com'
}
export const socket = io(baseURL);


