import { combineReducers } from 'redux'
import authReducer from './auth.reducer'
import photoReducer from './photo.reducer';
import postReducer from './post.reducer';
import userReducer from './user.reducer';
import notificationReducer from './notification.reducer'
import { reducer as formReducer } from "redux-form";
export default combineReducers({
    form: formReducer,
    authReducer,
    photoReducer,
    postReducer,
    userReducer,
    notificationReducer
})