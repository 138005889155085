import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../store/actions/auth.actions';
import { profileView } from '../../../store/actions/profile.action';
import { getNotify, new_notification } from '../../../store/actions/notification.action'
import { NavLink, withRouter, Link } from 'react-router-dom';
import { Bmodal } from '../pointModal/point';
import NavModal from './navmodal'
import { NotificationDropdown } from '../Notifications/notificationDropdown'
import '../../../styles.css'

class navbar extends Component {
    state = {
        isOpen: false,
        isModalOpen: false
    }
    logoutHandler = () => {
        this.props.logout();
    }
    profileViewHandler = () => {
        this.props.profileView();
        this.props.history.push("/profile/" + this.props.user._id)
    }
    onDirect = () => {
        this.props.history.push('/photos/all')
    }
    onRedirect = () => {
        this.props.history.push('/auth/login')
    }
    showModal = (show) => {
        this.setState({ isOpen: show })
    };
    showNavModal = (show) => {
        this.setState({ isModalOpen: show })
    }
    newNotificationHandler = () => {
        this.props.new_notification(false)
    }
    dataHandler = (id, type) => {
        this.props.new_notification(false)
        let content_type = type.toLowerCase();
        // eslint-disable-next-line no-unused-expressions
        content_type === 'post' ? this.props.history.push('/posts/' + id)
            : null
    }
    render() {
        const { points, notifications, notify_new, user } = this.props;
        let profile = (<>
            {localStorage.getItem('loginToken') || localStorage.getItem('registerToken') ?
                <>
                    <div
                        className="profile-logo bg-gradient-neutral"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <img className="nav-profile-user" src={this.props.user_thumbnail ? this.props.user_thumbnail : require('../../../assets/feed_icons/profile.svg')} alt="profile" />
                    </div>
                    <div className="dropdown-menu dropdown-menu-right " aria-labelledby="navbarDropdown">
                        <div className="btn dropdown-item" onClick={this.profileViewHandler}>
                            View Profile
                            </div>
                        <div className="dropdown-divider"></div>
                        <div
                            className="btn dropdown-item"
                            onClick={() => localStorage.getItem('loginToken') || localStorage.getItem('registerToken')
                                ? (localStorage.clear(), this.props.history.push("/auth/login"), this.logoutHandler("hai"))
                                : null}>Logout</div>
                    </div>
                </>
                :
                <div onClick={this.onRedirect}>
                    <img style={{ width: "48px", height: "48px", objectFit: 'cover', borderRadius: "30px", cursor: "pointer" }} src={this.props.user_thumbnail ? this.props.user_thumbnail : require('../../../assets/feed_icons/profile.svg')} alt="profile" />
                </div>
            }
        </>
        )
        return (
            <header className="navbar navbar-expand-lg navbar-light top-0 position-sticky d-flex" >
                <div className="w-100 d-flex top-bar align-items-center px-lg-3">
                    <button className="mr-lg-2 btn border-0 d-lg-none d-xl-none " onClick={() => this.setState({ isModalOpen: !this.state.isModalOpen })}>
                        <img src={require('../../../assets/common/menu.svg')} alt="" />
                    </button>
                    <NavLink to='/home' className="navbar-brand text-black-brand nav mr-2">
                        <div className="d-flex align-items-center">
                            <img className="nav-icon" src={require('../../../assets/navicon/group.png')} alt="navlogo" />
                            <span className="pl-2 d-none d-lg-block d-xl-block d-sm-none d-md-none" style={{ fontFamily: "'IBM Plex Sans', sans-serif" }}>
                                FotoGramam
                        </span>
                        </div>
                    </NavLink>
                    <NavModal show={this.state.isModalOpen} onHide={() => this.showNavModal(false)} />
                    <Bmodal show={this.state.isOpen} onHide={() => this.showModal(false)} />
                    <div className="d-flex nav-items align-items-center">
                        <div className=" text-dark h-100 d-none d-lg-block d-xl-block d-sm-none d-md-none ml-lg-auto" id="navbarSupportedContent">
                            <ul className="navbar-nav w-100">
                                <li className="d-flex justify-content-center w-100">
                                    <div className="nav-item  mr-lg-2">
                                        <NavLink to="/home" className="btn btn-navbar home" id='#nav-item'>Home</NavLink>
                                    </div>
                                    <div className="nav-item">
                                        <NavLink to="/photos" className="btn btn-navbar photos" onClick={this.onDirect}>Photos</NavLink>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex flex-column ml-auto mr-lg-3 align-items-center justify-content-center mr-2">
                            <div className="nav-user">{this.props.userName}</div>
                            {localStorage.getItem('loginToken')
                                ? < div className="nav-points w-100 text-right">{this.props.points}</div>
                                : null
                            }
                        </div>
                        <div className="nav-item dropdown dropdown-menu-right d-flex align-items-center" >
                            {profile}
                        </div>
                        <div className="d-flex flex-lg-row flex-row-reverse flex-xl-row ml-2 ">
                            <div className="mx-lg-3 d-flex align-items-center">
                                <div
                                    className="bell-icon mx-2"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    onClick={this.newNotificationHandler}
                                >
                                    <img className="bell" style={{ width: "17px", height: "20px" }} src={require('../../../assets/feed_icons/bell.svg')} alt="notifications" />
                                    {notify_new ?
                                        <div className="dot"></div>
                                        :
                                        null
                                    }
                                </div>
                                <div className="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="navbarDropdown">
                                    <NotificationDropdown data={notifications} user={user} dataHandler={this.dataHandler} handler={(path) => path ? this.props.history.push('/notifications') : null} />
                                </div>
                            </div>
                            <div className="nav-item dropdown mr-lg-2">
                                <button
                                    className="btn btn-create publish"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    <strong>
                                        Publish
                                </strong>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <Link to={points < 0 ? null : "/createPost"} className="dropdown-item" onClick={() => points < 0 ? this.showModal(true) : null}>Post</Link>
                                    <div className="dropdown-divider"></div>
                                    <Link to={points < 0 ? null : "/uploadPhotos"} className="dropdown-item" onClick={() => points < 0 ? this.showModal(true) : null}>Photograph</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </header >
        );
    }
}
const mapStateToProps = state => {

    return {
        user: state.authReducer.currentUser,
        u_id: state.authReducer.currentUser && state.authReducer.currentUser._id,
        userName: state.authReducer.currentUser && state.authReducer.currentUser.u_userName,
        points: state.authReducer.points,
        alldata: state.postReducer.alldata,
        allfetched: state.postReducer.allfetched,
        user_thumbnail: state.authReducer.currentUser && state.authReducer.currentUser.thumbnail,
        notifications: state.notificationReducer.notifications,
        notify_new: state.notificationReducer.new_notification
    }
};

const mapDispatchToProps = {
    logout,
    profileView,
    getNotify,
    new_notification
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(navbar));