// auth types
export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';
export const GET_USER_NAMES = 'GET_USER_NAMES';
export const USER_AVAILABLE = 'USER_AVAILABLE';

// all notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';

// all post fetch
export const ALL_POSTS = 'ALL_POSTS';
export const ALL_POSTS_FETCHED = 'ALL_POSTS_FETCHED';
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
export const TRENDING_POSTS = 'TRENDING_POSTS';
export const TREND_FETCHED = 'TREND_FETCHED';
export const DISCUSS = 'DISCUSS';
export const DISCUSS_COMMENT_COUNT = 'DISCUSS_COMMENT_COUNT';
export const DISCUSS_FETCHED = 'DISCUSS_FETCHED';
export const CLEAR_DISCUSS = 'CLEAR_DISCUSS';
export const CLEAR_COMMENT_COUNT = 'CLEAR_COMMENT_COUNT';

// all users data fetch
export const ALL_USERS = 'ALL_USERS';
export const USERS_FETCHED = 'USERS_FETCHED';

// single profile data
export const USER_PROFILE = 'USER_PROFILE';
export const POINTS = 'POINTS'
export const USER_PROFILE_POSTS = 'USER_PROFILE_POSTS';
export const USER_POSTS = 'USER_POSTS';
export const USER_PHOTOS = 'USER_PHOTOS';
export const USER_PROFILE_FETCHED = 'USER_PROFILE_FETCHED';
export const USER_LOG = 'USER_LOG';
export const LOG_FETCHED = 'LOG_FETCHED';

// profile data
export const PROFILE_FETCHED = 'PROFILE_FETCHED';
export const FETCH_DATA = 'FETCH_DATA';

// selected post
export const GET_POST = 'GET_POST';
export const GET_COMMENT_COUNT = 'GET_COMMENT_COUNT';
export const POST_SELECTED = 'POST_SELECTED';
export const RELATED_POST = 'RELATED_POST';

// comment session
export const COMMENTS = 'COMMENTS';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const ADD_COMMENTS = 'ADD_COMMENTS';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
export const REPLACE_COMMENTS = 'REPLACE_COMMENTS';
export const COMMENT_COUNT = 'COMMENT_COUNT';

// vote session
export const CREATE_VOTE = 'CREATE_VOTE';
export const POST_VOTES = 'POST_VOTES';
export const USER_VOTE = 'USER_VOTE';

// photos session
export const GET_PHOTO = 'GET_PHOTO';
export const TRENDING = 'TRENDING';
export const PHOTO_FETCHED = 'PHOTO_FETCHED';
export const ADD_PHOTOS = 'ADD_PHOTOS';
export const SELECTED_PHOTO = 'SELECTED_PHOTO';
export const UPDATE_PHOTO = "UPDATE_PHOTO";
export const PHOTO_SELECTED = 'PHOTO_SELECTED';
export const POST_THUMBNAIL = 'POST_THUMBNAIL';
export const CLEAR_THUMBNAIL = 'CLEAR_THUMBNAIL';
export const CLEAR_GALLERY = 'CLEAR_GALLERY';
export const PHOTO_COMMENT = 'PHOTO_COMMENT';
export const PHOTO_COMMENTS = 'PHOTO_COMMENTS';
export const ADD_PHOTO_COMMENTS = 'ADD_PHOTO_COMMENTS';
export const PHOTO_COMMENTS_FETCHED = 'PHOTO_COMMENTS_FETCHED';
export const CREATE_PHOTO_VOTE = 'CREATE_PHOTO_VOTE';
export const COMMENT_VOTES = 'COMMENT_VOTES';

// selected post saved as drfats
export const DRAFT_FETCHED = 'DRAFT_FETCHED';
export const DRAFT_THUMBNAIL = 'DRAFT_THUMBNAIL';
export const CLEAR_DRAFT = 'CLEAR_DRAFT';
export const DRAFT_STATUS = 'DRAFT_STATUS';
export const DRAFT_REMOVAL = 'DRAFT_REMOVAL';

// typeAhead
export const TYPEAHEAD = 'TYPEAHEAD'
export const TAGS = 'TAGS';
export const POST_CONTENT = 'POST_CONTENT';

// tags in posts
export const TAG_CREATION = 'TAG_CREATION';
export const TAG_REMOVAL = 'TAG_REMOVAL';
export const CLEAR_TAGS = 'CLEAR_TAGS';

// search tags
export const SEARCHTAG = 'SEARCHTAG';
export const REMOVE_SEARCH_TAG = 'REMOVE_SEARCH_TAG';