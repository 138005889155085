import axios from '../../axios';
import {
    ALL_USERS, LOGIN, USERS_FETCHED, FETCH_DATA, PROFILE_FETCHED,
    USER_AVAILABLE, USER_POSTS, USER_PHOTOS, POINTS, USER_LOG, LOG_FETCHED
} from './types';



// set user
export const setUser = (data, callback) => async dispatch => {
    try {
        let user = JSON.parse(data)
        if (user) {
            dispatch({ type: LOGIN, payload: user })
            dispatch({ type: POINTS, payload: 0 })
            let response = await axios.post('/users/' + user._id);
            if (response.status === 200) {
                dispatch({ type: POINTS, payload: response.data.data.points })
            }
            dispatch({ type: USER_AVAILABLE, payload: true })
        }
        callback();
    } catch (error) {
        console.log(error)
    }
}
export const getAllProfiles = () => async dispatch => {
    try {
        const response = await axios.get('/users');
        if (response.status === 200) {
            dispatch({ type: ALL_USERS, payload: response.data.data });
            dispatch({ type: USERS_FETCHED, payload: true });
        }
    } catch (error) {
        console.log(error);
    }
}

export const getUser = (data, callback) => async dispatch => {
    try {
        dispatch({ type: PROFILE_FETCHED, payload: false })
        let response = await axios.post('/users/' + data);
        if (response.status === 200) {
            dispatch({ type: USER_POSTS, payload: response.data.postcount });
            dispatch({ type: USER_PHOTOS, payload: response.data.photocount })
        }
    } catch (error) {
        console.log(error);

    }
}

export const getUserPosts = (data) => async dispatch => {
    try {
        let response = await axios.get('/users/' + data)
        if (response.status === 200) {
            dispatch({ type: FETCH_DATA, payload: response.data.data })
            dispatch({ type: PROFILE_FETCHED, payload: true })
        }
    } catch (error) {

    }
}

export const getUserLogs = (user) => async dispatch => {
    try {
        dispatch({ type: USER_LOG, payload: [] })
        dispatch({ type: LOG_FETCHED, payload: false })
        let response = await axios.get('/users/log/' + user);
        if (response.status === 200) {
            dispatch({ type: USER_LOG, payload: response.data.log })
            dispatch({ type: LOG_FETCHED, payload: true })
        }
    } catch (error) {

    }
}