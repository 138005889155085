import React, { Component } from 'react';
import { NavLink, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPosts } from '../../store/actions/post.actions';
import { userPhotos, clearGallery } from '../../store/actions/photo.actions'
import { getUser, getUserLogs } from '../../store/actions/users.actions';
import { ProfileLoader } from '../shared/contentSkeletons/skeleton';
import Footer from '../shared/footer'
import Posts from '../shared/userPosts/posts';
import Gallery from '../shared/photoGallery/gallery';
import UploadModal from '../shared/profileUploadModal/uploadModal';
import LogModal from '../shared/logModal/log';
import '../../styles.css';
import './profile.css';

class profile extends Component {
    state = {
        show: false,
    }
    timer;
    componentDidMount() {
        document.title = [this.props.u_name ? this.props.u_name : 'Profile', '| Fotogrmam'].join(' ')
        this.timer = setTimeout(() => {
            this.loaddata()
        }, 200)
    }
    componentDidUpdate(prevProps) {
        if (window.scrollY !== 0) { window.scrollTo(0, 0) }
        if (this.props.match.params.id !== prevProps.match.params.id) { this.componentDidMount() }
        if (this.props.data && this.props.data.length !== prevProps.data.length) { this.loaddata() }
    }
    loaddata() {
        this.props.getUser(this.props.match.params.id);
        this.props.getPosts({ id: this.props.match.params.id, auth: this.props.match.params.id === this.props.logged_user ? 'true' : 'false', user: this.props.logged_user });
    }
    loadGallery = () => {
        this.props.clearGallery()
        this.props.userPhotos(this.props.user._id);
    }
    logHandler = () => {
        if (this.props.match.params.id === this.props.logged_user) {
            this.logShow(true)
            this.props.getUserLogs(this.props.user._id)
        }
    }
    logShow = (show) => {
        this.setState({ show });
    }
    redirectHandler = (id, type) => {
        type === 'post'
            ? this.props.history.push('/posts/' + id)
            : this.props.history.push('/profile/' + id)
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    render() {
        const { auth, user, photoCount, postCount } = this.props;        
        const profile_ui = (
            <>
                {
                    auth ?
                        <div className="d-flex flex-column flex-xl-row flex-lg-row" style={{ borderRadius: "5px" }}>
                            <div className="align-items-center mr-lg-3 d-none d-lg-block d-xl-block d-sm-none d-md-none" style={{ borderRadius: "5px", height: "100%", width: "280px", backgroundColor: "white", position: "sticky", top: "115px", marginTop: "45px" }}>
                                <img className="mt-3" src={user.thumbnail ? user.thumbnail : require('../../assets/feed_icons/profile.svg')} style={{ width: "140px", height: "140px", borderRadius: "50%", objectFit: "cover", position: "relative" }} alt="profile images" />
                                {
                                    this.props.match.params.id === this.props.logged_user
                                        ? <button className="btn" data-toggle="modal" data-target=".upload-modal" style={{ position: "absolute", zIndex: 2, padding: "0px 5px", right: "85px", borderRadius: "15px", top: "129px", backgroundColor: "#000000" }}>
                                            <img src={require('../../assets/common/edit.svg')} alt="edit" style={{ height: "12px", width: "12px" }} />
                                        </button>
                                        : null
                                }
                                <div className="mt-3 border-bottom pb-3 mx-3">
                                    <div className="profile-user-name pb-1">{user.u_userName}</div>
                                    <div className="profile-user-fullname"> {user.u_fullName} </div>
                                </div>
                                <div className="mt-4 mx-3 d-flex justify-content-around">
                                    <div className="d-flex flex-column">
                                        <span className="post-count">{postCount}</span>
                                        <span className="post-count-text">Posts</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="photo-count">{photoCount}</span>
                                        <span className="photo-count-text">Photos</span>
                                    </div>
                                </div>
                                <button className="btn w-100 btn-points d-flex align-items-center justify-content-center" style={{ marginTop: "20px" }} onClick={() => { this.logHandler(); }}>
                                    <span className="point-num pr-1">{user.points}</span>
                                    <span className="point-text">points</span>
                                </button>
                            </div>
                            <div className="d-lg-none d-xl-none">
                                <img className="mt-3" src={user.thumbnail ? user.thumbnail : require('../../assets/feed_icons/profile.svg')} style={{ width: "80px", height: "80px", borderRadius: "50%", objectFit: "cover", position: "relative" }} alt="profile images" />
                                {
                                    this.props.match.params.id === this.props.logged_user
                                        ? <button className="btn" data-toggle="modal" data-target=".upload-modal" style={{ position: "absolute", zIndex: 2, padding: "0px 8px", right: "140px", borderRadius: "15px", top: "126px", backgroundColor: "#000000" }}>
                                            <img src={require('../../assets/common/edit.svg')} alt="edit" style={{ height: "8px", width: "8px" }} />
                                        </button>
                                        : null
                                }
                                <div className="mt-3 border-bottom pb-3 mx-3">
                                    <div className="profile-user-name pb-1">{user.u_userName}</div>
                                    <div className="profile-user-fullname"> {user.u_fullName} </div>
                                </div>
                                <div className="mt-3 mx-3 d-flex justify-content-around">
                                    <div className="d-flex flex-column">
                                        <span className="post-count">{postCount}</span>
                                        <span className="post-count-text">Posts</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="photo-count">{photoCount}</span>
                                        <span className="photo-count-text">Photos</span>
                                    </div>
                                </div>
                                <button className="btn w-100 btn-points d-flex align-items-center justify-content-center" style={{ marginTop: "20px" }} onClick={() => { this.logHandler(); }}>
                                    <span className="point-num pr-1">{user.points}</span>
                                    <span className="point-text">points</span>
                                </button>

                            </div>
                            <LogModal show={this.state.show} onHide={() => this.logShow(false)} handler={this.logShow} redirect={this.redirectHandler} />
                            <div className="profile-posts w-100">
                                <div className="d-flex flex-column">
                                    <div className="profile-navbar">
                                        <div className=" mt-2 d-flex mx-2 border-bottom" >
                                            <NavLink to={this.props.match.url + "/posts"} className="btn btn-tool mr-5 pb-4">Discussions</NavLink>
                                            <NavLink to={this.props.match.url + "/gallery"} className="btn btn-tool pb-4" onClick={this.loadGallery}>Photos</NavLink>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column profile-navbar-contents">
                                        <Switch>
                                            <Route path={`/profile/${this.props.match.params.id}/posts`} render={() => <Posts />} />
                                            <Route path={`/profile/${this.props.match.params.id}/gallery`} render={() => <Gallery />} />
                                        </Switch>
                                        <Redirect from={`/profile/${this.props.match.params.id}`} to={`/profile/${this.props.match.params.id}/posts`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="p-5">
                            <ProfileLoader />
                        </div>
                }
            </>
        );
        return (
            <div className="d-flex flex-column" style={{ minHeight: "95vh" }} >
                <div className="profile container">
                    {profile_ui}
                    <UploadModal />
                </div>
                <div className="mt-auto">
                    <Footer />
                </div>
            </div >
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.userReducer.userProfile,
        logged_user: state.authReducer.currentUser && state.authReducer.currentUser._id,
        u_name: state.userReducer.userProfile && state.userReducer.userProfile.u_userName,
        data: state.postReducer.fetchdata,
        auth: state.userReducer.profilefetched,
        photos: state.photoReducer.photos,
        photoCount: state.userReducer.userPhotos,
        postCount: state.userReducer.userPosts
    }
};

const mapDispatchToProps = {
    getUser,
    getPosts,
    userPhotos,
    clearGallery,
    getUserLogs
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(profile))