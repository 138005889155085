import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { thumbnailUpload, draftThumbnail } from '../../store/actions/post.actions'
import './index.css'

class thumbnail extends Component {
    state = {
        photo: '',
        content: '',
        image: '',
        uploaded: false,
        disabled: true
    }
    componentDidMount() {
        this.setState({ image: this.props.default ? this.props.default : '', uploaded: this.props.default ? true : false }, () => {
            this.props.thumbnailUpload(this.state.image)
        })
    }
    componentDidUpdate(prevProps) {
        if (this.props.thumbnail !== prevProps.thumbnail) {
            this.props.draftThumbnail(false);
        }
    }
    onChangeHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            this.setState({ photo: event.target.files[0] })
            reader.onload = (e) => {
                this.setState({ image: e.target.result, uploaded: true });
                let file = this.state.photo;
                this.props.thumbnailUpload(file)
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    render() {
        return (
            <div className="mb-3" style={{ height: "auto" }}>
                <div className="d-flex flex-column">
                    <div className="text-left thumbnail">
                        Upload Thumbnail
                    </div>
                    <div className="thumbnail-border">
                        <div>
                            <img className="upload-svg" src={require('../../assets/photo-icons/photo.svg')} alt="upload photos" />
                        </div>
                        <div className="upload-text d-flex justify-content-center">
                            Drag & Drop to Upload or <div className="browse">&nbsp; Browse &nbsp;</div> to Choose an Image
                        </div>
                        <div className="upload-warn">Thumbnail should be of size 400x300 and of type jpg / png</div>
                        <input className="upload" type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={this.onChangeHandler}
                        />
                    </div>
                </div>
                <div>
                    {this.state.uploaded
                        ?
                        <div className="d-flex flex-column align-items-center p-2">
                            <img
                                className="bg-white border"
                                src={this.state.image} alt="upload"
                                style={{
                                    width: "200px",
                                    height: "200px",
                                    objectFit: 'cover'
                                }} />
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        thumbnail: state.photoReducer.thumbnail
    }
};

const mapDispatchToProps = {
    thumbnailUpload, draftThumbnail
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(thumbnail));
