import {
    GET_PHOTO, TRENDING, PHOTO_FETCHED, ADD_PHOTOS,
    SELECTED_PHOTO, PHOTO_SELECTED, POST_THUMBNAIL,
    CLEAR_THUMBNAIL, CLEAR_GALLERY, PHOTO_COMMENT,
    PHOTO_COMMENTS, PHOTO_COMMENTS_FETCHED,
    ADD_PHOTO_COMMENTS, CREATE_PHOTO_VOTE, COMMENT_VOTES, UPDATE_PHOTO
} from '../actions/types';

const initialState = {
    // photo session
    photos: [],
    photo_fetched: false,
    selected_photo: null,
    photo_selected: false,
    thumbnail: null,
    photo_comment: '',
    trending: null,
    comments: [],
    userPhotoVote: 0,
    comment_votes: []
}
const photoReducer = (state = initialState, action) => {
    switch (action.type) {
        // photo section
        case GET_PHOTO:
            return {
                ...state,
                photos: state.photos.concat(action.payload)
            }
        case TRENDING:
            return {
                ...state,
                trending: action.payload
            }
        case PHOTO_FETCHED:
            return {
                ...state,
                photo_fetched: action.payload
            }
        case ADD_PHOTOS:
            let new_array = [action.payload].concat(state.photos)
            return {
                ...state,
                photos: new_array
            }
        case UPDATE_PHOTO:
            let photo = state.photos[action.payload.index]
            if (action.payload.type === 'vote') { photo.userVoted = action.payload.vote; photo.photo.upvote_count = action.payload.upvote; photo.photo.downvote_count = action.payload.downvote }
            if (action.payload.type === 'comment') { photo.commentCount = action.payload.commentCount }
            state.photos.splice(action.payload.index, 1, photo)
            return state
        case SELECTED_PHOTO:
            return {
                ...state,
                selected_photo: action.payload
            }
        case PHOTO_SELECTED:
            return {
                ...state,
                photo_selected: action.payload
            }
        case CLEAR_GALLERY:
            let cleanGallery = [];
            return {
                ...state,
                photos: cleanGallery
            }



        // post thumbnail
        case POST_THUMBNAIL:
            return {
                ...state,
                thumbnail: action.payload
            }
        case CLEAR_THUMBNAIL:
            return {
                ...state,
                thumbnail: null
            }

        // photocomment
        case PHOTO_COMMENT:
            return {
                ...state,
                photo_comment: action.payload
            }
        case PHOTO_COMMENTS:
            let comment_array = action.payload;
            return {
                ...state,
                comments: comment_array
            }
        case PHOTO_COMMENTS_FETCHED:
            return {
                ...state,
                comments_fetched: action.payload
            }
        case ADD_PHOTO_COMMENTS:
            let updated_array = [action.payload].concat(state.comments)
            return {
                ...state,
                comments: updated_array
            }

        // photovote
        case CREATE_PHOTO_VOTE:
            return {
                ...state,
                userPhotoVote: action.payload
            }
        case COMMENT_VOTES:
            return {
                ...state,
                comment_votes: action.payload
            }
        default:
            return state
    }
}

export default photoReducer;