import React, { Component } from 'react';
import sizeMe from 'react-sizeme';
import StackGrid from 'react-stack-grid';

class stackGrid extends Component {
    render() {        
        const { stackWidth, gutter } = this.props;
        const { size: { width } } = this.props;
        return (
            <div style={{ marginTop: `${gutter ? gutter : 25}px` }}>
                <StackGrid
                    monitorImagesLoaded
                    columnWidth={width <= 768 ? '100%' : stackWidth ? stackWidth : "25%"}
                    duration={600}
                    gutterWidth={24}
                    gutterHeight={24}
                    easing={'cubic-bezier(0.165, 0.84, 0.44, 1)'}
                >
                    {this.props.data}
                </StackGrid>
            </div>
        );
    }
}

export default sizeMe()(stackGrid);