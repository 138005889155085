import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { lastUpdatedTime } from '../../common/timeAgo/timeAgo'
import { getPost, removeDraft, fetchDraft, publish } from '../../../store/actions/post.actions';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-toastify/dist/ReactToastify.css';
import './posts.css'
class posts extends Component {
    state = {
        draftFetched: null,
        post: null
    }
    redirectHandler(data) {
        this.props.history.push('/posts/' + data);
    }
    deleteHandler(data) {
        this.props.removeDraft(data, (err) => { if (!err) { this.notifyDraftRemoved(); } })
    }
    draftHandler(id) {        
        this.props.fetchDraft(id, (err) => !err ? this.props.history.push('/createpost') : null);
    }
    publishHandler(postId) {
        let published_at = new Date()
        let data = { id: postId, status: 'published', published_at: published_at }
        this.props.publish(data, (err) => { if (err) { this.notifyDraftPublished(); } });
    }
    notifyDraftRemoved = () => toast("Removed from drafts", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
    });
    notifyDraftPublished = () => toast("Draft published sucessfully");
    render() {
        const { data, user_id } = this.props;
        let post = (
            data.length !== 0 && this.state.data !== null
                ? data.map((post, i) => {
                    return (
                        <li key={i} className="post d-flex my-4 p-4" style={{ backgroundColor: "white", cursor: 'pointer' }} >
                            <div className="pr-3">
                                <button className={["btn-vote-post", post.userVoted ? 'active' : 'border'].join(' ')}>
                                    <img className="vote-icon" src={post.userVoted ? require('../../../assets/feed_icons/voteActive.svg') : require('../../../assets/photo-icons/vote.svg')} alt="vote" />
                                    <div className="vote-count">{post.post && post.post.upvote_count}</div>
                                </button>
                            </div>
                            <div className="d-flex flex-column ml-2 w-100">
                                <div className="d-flex w-100 border-bottom">
                                    <div className="pr-2 pb-2 w-100" onClick={() => this.redirectHandler(post.post._id)}>
                                        <div className="text-left post-title">
                                            {post.post && post.post.title}
                                        </div>
                                        <div className="text-left pb-3 post-desc">
                                            {post.post && post.post.description}
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column align-items-center ml-auto">
                                        {
                                            user_id === post.post.created_by._id
                                                ?
                                                <div className="dropdown">
                                                    <button
                                                        className="btn dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <img src={require('../../../assets/common/dropdown.svg')} alt="dropdown" />
                                                    </button>
                                                    <div className=" dropdown-menu text-center" aria-labelledby="dropdownMenuButton">
                                                        {post.post.status === 'draft'
                                                            ? <div className="d-flex flex-column">
                                                                <button className="btn btn-sm " onClick={() => this.publishHandler(post.post._id)}>PUBLISH</button>
                                                                <button className="btn btn-sm " onClick={() => this.draftHandler(post.post._id)}>EDIT</button>
                                                                <button className="btn btn-sm " onClick={() => this.deleteHandler(post.post._id)}>DELETE</button>
                                                            </div>
                                                            :
                                                            <div>
                                                                <button className="btn btn-sm " onClick={() => this.deleteHandler(post.post._id)}>DELETE</button>
                                                                <button className="btn btn-sm ">UNPUBLISH</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {post.post && post.post.status === 'draft' ? <div><strong>Draft</strong></div> : null}
                                    </div>

                                </div>
                                <div className="pt-3 d-flex">
                                    <div className="d-flex">
                                        <button className="btn d-flex align-items-center border mr-3">
                                            <img className="mr-1 post-comment" src={require('../../../assets/feed_icons/comment.svg')} alt="vote" />
                                            <div className="comment-count" >{post.commentCount}</div>
                                        </button>
                                        <button className="btn border text-capitalize btn-post-tag">{post.post && post.post.tag}</button>
                                    </div>
                                    <div className="ml-auto post-time">{lastUpdatedTime(post.post && post.post.created_at)}</div>
                                </div>
                            </div>
                        </li >
                    );
                })
                : <div>No posts yet</div>)
        return (
            <div className="mx-2">
                <ToastContainer
                    position="top-right"
                    transition={Zoom}
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover={false}
                />
                <ul className="list-group d-flex" style={{ listStyleType: 'none' }}>
                    {post}
                </ul>
                {/* <Modal dataselected={this.state.draftFetched} /> */}
            </div>
        );
    }
}
const mapStateToProps = state => {    
    return {
        data: state.postReducer.userProfilePosts,
        user_id: state.authReducer.currentUser && state.authReducer.currentUser._id
    }
};

const mapDispatchToProps = {

    getPost,
    fetchDraft,
    removeDraft,
    publish
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(posts));
