import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Colors } from '../common/colors/colors'
import { uploadPhoto, clearGallery, getPhotoTags } from '../../store/actions/photo.actions';
import Footer from '../shared/footer'
import TextareaAutosize from 'react-textarea-autosize';
import TypeAhead from '../common/typeAhead/typeAhead'
import './photograph.css';

class photograph extends Component {
    state = {
        photo: '',
        content: '',
        image: '',
        uploaded: false,
        disabled: true,
        height: null
    }
    componentDidMount() {
        document.title = 'Upload Photo | Fotogramam';
        this.props.getPhotoTags()
    }
    onContentChangeHandler = (event) => {
        if (event.target.value === '') {
            this.setState({ content: '', disabled: true })
        } else {
            this.setState({ content: event.target.value, disabled: false })
        }
    }
    onChangeHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            this.setState({ photo: event.target.files[0] })
            reader.onload = (e) => {
                this.setState({ image: e.target.result, uploaded: true });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    onUploadHandler = () => {
        this.setState({ disabled: true })
        const uploaded_at = new Date();
        const file = this.state.photo;
        const content = this.state.content;
        const color = Colors();

        const data = {
            uploaded_at: uploaded_at,
            file: file,
            content: content,
            color: color,
            height: this.state.height,
            tag: this.props.typeahead
        }
        this.props.uploadPhoto(data, (err) => {
            if (!err) {
                this.setState({ disabled: true })
                this.props.clearGallery();
                this.props.history.push("/photos/" + this.props.typeahead)
            }
        })
        this.setState({ content: '' })
    }
    imgOnLoad = () => {
        var img = document.getElementById('upload-img');
        var height = img.clientHeight;
        this.setState({ height: [height, 'px'].join('') })
    }
    render() {
        return (
            <>
                <div className="mt-3 container">
                    <div className="text-left thumbnail-head">
                        Upload Photo
                        </div>
                    <div className="d-flex flex-column mb-2"
                        style={{ minHeight: "100vh", backgroundColor: "white" }}
                    >
                        <div className="thumbnail-border m-3 m-xl-4 m-lg-4" style={{ position: 'relative' }}>
                            <div>
                                <img className="upload-svg" src={require('../../assets/photo-icons/photo.svg')} alt="upload photos" />
                            </div>
                            <div className="upload-text d-flex justify-content-center">
                                Drag & Drop to Upload or <div className="browse">&nbsp; Browse &nbsp;</div> to Choose an Image
                        </div>
                            <div className="upload-warn">Thumbnail should be of size 400x300 and of type jpg / png</div>
                            <input className="photograph" type="file"
                                accept=".jpg,.jpeg,.png"
                                onChange={this.onChangeHandler}
                            />
                        </div>


                        <div>
                            {this.state.uploaded
                                ?
                                <div className="d-flex flex-column align-items-center">
                                    <div className="w-100">
                                        <img
                                            className="mx-3 ml-xl-4 ml-lg-4 bg-white border mb-2 float-left"
                                            id="upload-img"
                                            onLoad={this.imgOnLoad}
                                            src={this.state.image} alt="upload" />
                                    </div>
                                    <div className="w-100 d-flex flex-column">
                                        <div className=" mx-3 mx-xl-4 mx-lg-4 mt-4 upload-typeahead">
                                            <TypeAhead />
                                        </div>
                                        <div className="d-flex upload-textarea-div mx-3 mx-xl-4 mx-lg-4 mt-4 align-items-end">
                                            <TextareaAutosize
                                                className="text-dark border upload-textarea my-2 upload-comment"
                                                style={{ minHeight: "10rem" }}
                                                placeholder="Write a caption"
                                                value={this.state.content}
                                                onChange={this.onContentChangeHandler}
                                            />
                                        </div>
                                        <div className="d-flex ml-4 my-3">
                                            <button className="btn btn-create" disabled={this.state.disabled} onClick={this.onUploadHandler}>UPLOAD</button>
                                            <button className="btn border-0 ml-3 btn-upload-cancel align-items-center" onClick={() => this.props.history.push('/home')}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return { data: state.postReducer.fetchdata, typeahead: state.postReducer.typeahead, }
};

const mapDispatchToProps = {
    uploadPhoto, clearGallery, getPhotoTags
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(photograph));
