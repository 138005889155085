import React, { Component } from 'react';
import { lastUpdatedTime } from '../timeAgo/timeAgo';
import { connect } from 'react-redux';
import { createVote } from '../../../store/actions/post.actions';
import { createPhotoVote, updatePhoto } from '../../../store/actions/photo.actions';
import Vote from '../vote/vote'
import './comment.css'
class Comment extends Component {
    state = {
        count: 5,
        next: 5,
        visible: false
    }
    componentDidMount() {
        if (this.props.comments && this.props.comments.length > 5) this.setState({ visible: true })
    }
    componentDidUpdate(prevProps) {
        if (this.props.comments.length !== prevProps.comments.length) {
            this.props.updatePhoto({ index: this.props.index, commentCount: this.props.comments.length, type: 'comment' })
        }
    }
    createVote = (id, vote) => {
        let data = {
            id: id,
            vote: vote,
            post_id: this.props.post_id ? this.props.post_id : null,
            photo_id: this.props.photo_id ? this.props.photo_id : null,
            type: 'comment',
            comment_id: id
        }
        this.props.type === 'photo'
            ? this.props.createPhotoVote(data)
            : this.props.createVote(data)
    }
    loadmore = () => {
        this.setState({ visible: false, count: this.props.comments.length })
    }
    modalShowHandler = (show) => {
        this.props.modalHandler(show)
    }
    render() {
        const { comments, type, commentCount, points } = this.props;
        return (
            comments && type === 'photo' ?
                comments.map((comment, i) => {
                    return (

                        <div key={i} className="d-flex flex-row py-2">
                            <div>
                                {
                                    comment.comment.user.thumbnail ?
                                        <img className={["mr-2", type === 'photo' ? 'photo-comment-thumbnail' : 'post-comment-thumbnail'].join(' ')} src={comment.comment.user.thumbnail} alt="profile" /> : null
                                }

                            </div>
                            <div className="align-items-baseline d-flex flex-column">
                                <div className="d-flex text-left ">
                                    <div className={type === 'photo' ? 'photo-comment-user' : 'post-comment-user'}>
                                        {comment.comment.user.u_userName}
                                    </div>
                                    <div className={["ml-1", type === 'photo' ? 'photo-comment-time' : 'post-comment-time'].join(' ')}>{lastUpdatedTime(comment.comment.created_at)}</div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className={["pl-2 text-left text-break", type === 'photo' ? 'photo-comment-comment' : 'post-comment-comment'].join(' ')}>{comment.comment.comment}</div>
                                </div>
                            </div>
                            <div className="ml-auto">
                                <Vote points={points} data={comment.comment} type={'comment'} vote={comment.userVote} vote_type='comment' handler={this.createVote} modalShowHandler={this.modalShowHandler} />
                            </div>
                        </div>

                    )
                })
                :
                comments && type === 'post' ?
                    <>{
                        comments.slice(0, this.state.count).map((comment, i) => {
                            return (

                                <div key={i} className="d-flex flex-column my-3 p-3 comment-border">
                                    <div className="d-flex">
                                        {
                                            comment.comment.user.thumbnail ?
                                                <img className="mr-2 post-comment-thumbnail" src={comment.comment.user.thumbnail} alt="profile" /> : null
                                        }
                                        <div className="d-flex flex-column text-left ">
                                            <div className='post-comment-user'>
                                                {comment.comment.user.u_userName}
                                            </div>
                                            <div className="post-comment-time">{lastUpdatedTime(comment.comment.created_at)}</div>
                                        </div>
                                        <div className="ml-auto">
                                            <Vote points={points} data={comment.comment} type={'comment'} vote={comment.userVote} vote_type='comment' handler={this.createVote} modalShowHandler={this.modalShowHandler} />
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div className="text-left text-break post-comment-comment">{comment.comment.comment}</div>
                                    </div>
                                </div>

                            )
                        })
                    }
                        {this.state.visible && comments.length > 5
                            ? <div className="my-4">
                                <button className="btn btn-comment-more" onClick={this.loadmore}>View all {commentCount} comments</button>
                            </div>
                            : null
                        }
                    </>
                    : null
        );
    }
}
const mapStateToProps = state => {
    return {
        comment: state.postReducer.createComment,
        thumbnail: state.authReducer.currentUser && state.authReducer.currentUser.thumbnail,
        commentCount: state.postReducer.postCommentCount,
        points: state.authReducer.points
    }
};
const mapDispatchToProps = {
    createVote, createPhotoVote, updatePhoto
}
export default connect(mapStateToProps, mapDispatchToProps)(Comment);