import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { comments } from '../../../store/actions/post.actions';
import { lastUpdatedTime } from '../../common/timeAgo/timeAgo'
import { getPost, relatedContents } from '../../../store/actions/post.actions';
import { ArticleLoader } from '../contentSkeletons/skeleton'
import draftToHtml from 'draftjs-to-html';
import Footer from '../footer';
import CreateComment from '../../common/comment/createComment';
import Comments from '../../common/comment/comment';
import Related from '../relatedPosts/related'
import Vote from './vote';
import Modal from '../pointModal/modal';
import { Bmodal } from '../pointModal/point'
import './viewpost.css'

class viewPost extends Component {
    state = {
        scroll: true,
        isOpen: false,
        clientHeight: 0,
        scrollHeight: 0
    }
    componentDidMount() {
        document.title = 'Post | Fotogramam';
        window.scrollTo(0, 0);
        setTimeout(() => {
            let data = {
                id: this.props.match.params.id,
                user: this.props.user_id
            }
            this
                .props
                .getPost(data, (err) => {
                    this.onLoad();
                    if (!err) {
                        if (this.props.post.tag) { this.props.relatedContents({ tag: this.props.post.tag, user: this.props.user_id, post_id: this.props.match.params.id }) }
                    }
                });
        }, 500)
        window.addEventListener('scroll', this.onScroll);
    }

    componentDidUpdate() {
        if (window.scrollY !== 0 && this.state.scroll) {
            window.scrollTo(0, 0); this.setState({ scroll: false })
        }
    }
    onLoad = () => {
        var clientHeight = document.getElementById('myDiv');
        this.setState({ clientHeight: clientHeight.clientHeight })
    }
    onScroll = () => {
        this.setState({ scrollHeight: window.scrollY })

    }
    createComment = (comment) => {
        let data = {
            id: this.props.post._id,
            comment: comment
        }
        this
            .props
            .comments(data, err => { });
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.componentDidMount();
        }
    }
    redirect = (id) => {
        this.props.history.push('/posts/' + id)
    }
    showModal = (show) => {
        this.setState({ isOpen: show })
    };
    render() {
        const { comment, related } = this.props;
        return (
            <>
                <div
                    className="post-view pt-3 article-layout"
                    style={{
                        minHeight: "90vh",
                        backgroundColor: 'white'
                    }}>
                    {
                        this.props.auth
                            ?
                            <div className="position-relative">
                                <div className="container mb-2 mb-lg-4 mt-5 d-flex flex-column align-items-center">
                                    <button className="btn post-tag">{this.props.post.tag}</button>
                                    <h1
                                        style={{
                                            maxWidth: '600px'
                                        }}>{this.props.post.title}</h1>
                                    <div className="d-flex mt-5 mb-4">
                                        <div className=" ml-auto"><img className="btn-social" src={require('../../../assets/feed_icons/twitter.svg')} alt="" /></div>
                                        <div className=" mx-3"><img className="btn-social" src={require('../../../assets/feed_icons/facebook.svg')} alt="" /></div>
                                        <div className=" mr-auto"><img className="btn-social" src={require('../../../assets/feed_icons/linkedin.svg')} alt="" /></div>
                                    </div>
                                </div>
                                <aside
                                    style={{ opacity: this.state.clientHeight <= this.state.scrollHeight ? 0 : 1,pointerEvents: this.state.clientHeight <= this.state.scrollHeight ? "none" : "auto"  }}
                                    className="asideContent d-flex flex-row flex-lg-column flex-xl-column justify-content-lg-center justify-content-xl-center justify-content-between">
                                    <div className="d-flex mr-xl-auto mr-lg-auto d-flex flex-row flex-lg-column flex-xl-column aside-content-tools">
                                        <div className="post-group d-lg-flex flex-column align-items-center mb-xl-3 mb-lg-3 ">
                                            <div className="d-flex flex-row flex-lg-column flex-xl-column align-items-center">
                                                <img
                                                    className="mb-2 post-view-user-thumbnail"
                                                    src={this.props.post.created_by.thumbnail
                                                        ? this.props.post.created_by.thumbnail
                                                        : "https://i.ya-webdesign.com/images/avatar-pic-circle-png-18.png"}
                                                    alt="profile" />
                                                <div className="text-nowrap pb-1">
                                                    <div className="post-user">
                                                        {this.props.post.created_by.u_userName}</div>
                                                    <div className="post-time">{lastUpdatedTime(this.props.post.created_at)}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ml-auto ml-xl-0 ml-lg-0">
                                            <Vote status={this.props.post.status} modalHandler={this.showModal} />
                                        </div>
                                    </div>
                                </aside>
                                <div id="myDiv" className="article-container container-xl container-lg">
                                    <Bmodal show={this.state.isOpen} onHide={() => this.showModal(false)} />
                                    <div className="d-flex flex-column viewPost" style={{ position: 'relative', top: '-200px' }}>
                                        <div className="d-flex flex-column flex-lg-row flex-xl-row ">
                                            <div className="main-content container">
                                                {this.props.post.thumbnail
                                                    ? <div className="col-12 mb-5 p-0 container">
                                                        <div>
                                                            <img className="article-tumbnail"
                                                                src={this.props.post.thumbnail}
                                                                alt="article post" />
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                                <div className="article">
                                                    <h4 className="text-left text-italic font-medium font-weight-light">{this.props.post.description}</h4>
                                                    <article>
                                                        <div
                                                            className="pb-3 pt-3 article-section clearfix mb-4 text-left text-dark"
                                                            dangerouslySetInnerHTML={{
                                                                __html: draftToHtml(JSON.parse(this.props.post.content))
                                                            }} />
                                                        <div>
                                                            <div className="story">Share your story</div>
                                                            <div className="my-4 d-flex justify-content-center">
                                                                <div className=""><img className="btn-social" src={require('../../../assets/feed_icons/twitter.svg')} alt="" /></div>
                                                                <div className="mx-3"><img className="btn-social" src={require('../../../assets/feed_icons/facebook.svg')} alt="" /></div>
                                                                <div className=""><img className="btn-social" src={require('../../../assets/feed_icons/linkedin.svg')} alt="" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column mt-5">
                                                            <div className="pb-3">
                                                                <CreateComment rows={3} user={this.props.user} handler={this.createComment} modalHandler={this.showModal} type="post" />
                                                            </div>
                                                            <div>
                                                                <Comments comments={comment} type='post' post_id={this.props.post._id} modalHandler={this.showModal} />
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal />
                                </div>
                                <Related related={related} handler={this.redirect} />
                                <Footer />
                            </div>
                            :
                            <ArticleLoader />
                    }
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        userVote: state.postReducer.userVote,
        post: state.postReducer.selectedPost,
        comment: state.postReducer.comments,
        createComment: state.postReducer.createComment,
        postVotes: state.postReducer.postVotes,
        user: state.authReducer.currentUser,
        user_id: state.authReducer.currentUser && state.authReducer.currentUser._id,
        alldata: state.postReducer.data,
        auth: state.postReducer.postSelected,
        userAuth: state.authReducer.userAvailable,
        related: state.postReducer.relatedPost,
        points: state.authReducer.points
    }
};

const mapDispatchToProps = {
    getPost,
    comments,
    relatedContents
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(viewPost));