import React, { Component } from 'react';
import { socket } from '../../../socket.client';
import { addNotification } from '../../../store/actions/notification.action'
import { connect } from 'react-redux';
import { lastUpdatedTime } from '../../common/timeAgo/timeAgo'
import { withRouter } from 'react-router';
import Notify from './notify'
import './Notification.css';


class Notification extends Component {
    componentDidMount() {
        setTimeout(() => {
            socket.on('notification', (data) => {
                this.setState({ notification: data.data })
            })
            socket.on('new_notification', (data) => {
                this.props.addNotification(data.data)
            })
        }, 100)
    }
    dataHandler = (id, type) => {
        let content_type = type.toLowerCase();
        // eslint-disable-next-line no-unused-expressions
        content_type === 'post' ? this.props.history.push('/posts/' + id)
            : null
    }
    render() {
        const { notifications } = this.props;
        return (
            <div className="container notify-page mt-4">
                {
                    notifications.length !== 0 && notifications.slice(0, 10).map((notify, i) => {
                        return (
                            <div key={i} className="d-flex border-bottom mx-3 p-3 notification-content" onClick={() => this.dataHandler(notify.content_id._id, notify.type)}>
                                <div>
                                    <img className="notification-user-thumbnail" src={notify.content_user.thumbnail} alt="" />
                                </div>
                                <div className="d-flex flex-column notify-content w-100">
                                    <div className="d-flex notify-section1">
                                        <div className="notify-user-name">
                                            {notify.content_user.u_userName ? notify.content_user.u_userName : null}
                                        </div>
                                        <div className="notify-time">
                                            {lastUpdatedTime(notify.created_at)}
                                        </div>
                                    </div>
                                    <div className="notify-type d-flex justify-content-between">
                                        <Notify type={notify.activity_id.type} content_user={notify.content_user} action_user={notify.created_by} content_type={notify.type} />
                                        <img src={require('../../../assets/common/right.svg')} alt="" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.authReducer.currentUser && state.authReducer.currentUser._id,
        notifications: state.notificationReducer.notifications
    }
};

const mapDispatchToProps = {
    addNotification
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));
