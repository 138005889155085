import React from 'react'
import './topPeople.css'

export const TopPeople = (props) => {
    return (
        <div className="d-flex flex-column p-4">
            <div>
                <div className="headline text-left text-lg-center text-xl-center">People who are top in the week</div>
                <div className="sub-head text-left text-lg-center text-xl-center">I am honoured to announce that I have won the accolade of Top 10 SME blog in 2018 by Feedspot.</div>
            </div>
            <div className="d-flex people" style={{ overflowX: 'scroll' }}>
                {
                    props.people.slice(0, 10).map((person, i) => {
                        return (
                            <div key={i} className="d-flex flex-column person align-items-center" onClick={() => props.handler(person._id)} style={{ cursor: 'pointer' }}>
                                <img className="top-img" src={person.thumbnail ? person.thumbnail : require('../../../../assets/feed_icons/profile.svg')} alt='avatar' />
                                <div className="text-nowrap person-name">{person.u_userName}</div>
                                <div className="text-nowrap person-point">{person.points} points</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}