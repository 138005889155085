import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GalleryLoader = () => {
    return (
        <div >
            <SkeletonTheme color="#ececec">
                <div className="row mt-4">
                    <div className="col-12 row mt-4 pl-4 ml-0">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <div className="mb-4">
                                <Skeleton duration={1} height={"30vh"} />
                            </div>
                            <div className="mb-4">
                                <Skeleton duration={1} height={"40vh"} />
                            </div>
                            <div>
                                <Skeleton duration={1} height={"30vh"} />
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <div className="mb-4">
                                <Skeleton duration={1} height={"50vh"} />
                            </div>
                            <div className="mb-4">
                                <Skeleton duration={1} height={"25vh"} />
                            </div>
                            <div >
                                <Skeleton duration={1} height={"25vh"} />
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <div className="mb-4">
                                <Skeleton duration={1} height={"30vh"} />
                            </div>
                            <div className="mb-4">
                                <Skeleton duration={1} height={"30vh"} />
                            </div>
                            <div>
                                <Skeleton duration={1} height={"40vh"} />
                            </div>
                        </div>
                    </div>
                </div>
            </SkeletonTheme>
        </div>
    )
}

export const ArticleLoader = () => {
    return (
        <SkeletonTheme>
            <div className="d-none d-lg-block d-xl-block d-sm-none d-md-none" style={{ height: "100vh", margin: "0px 150px" }}>
                <div className="d-flex h-100" style={{ marginTop: "150px" }}>
                    <div className="d-flex flex-column" style={{ marginTop: "195px" }}>
                        <Skeleton circle={true} height={50} width={50} />
                        <Skeleton height={10} width={"100%"} />
                        <Skeleton height={40} width={40} />
                        <Skeleton height={40} width={40} />
                    </div>
                    <div className="w-100 h-100 d-flex flex-column" >
                        <div className="d-flex flex-column">
                            <Skeleton height={30} width={"60%"} />
                            <Skeleton height={30} width={"50%"} />
                            <Skeleton height={30} width={"55%"} />
                        </div>
                        <div className="d-flex justify-content-center mt-5 mb-4">
                            <Skeleton height={50} width={50} />
                            <div className="mx-3">
                                <Skeleton height={50} width={50} />
                            </div>
                            <Skeleton height={50} width={50} />
                        </div>
                        <div className="h-100">
                            <Skeleton height={"50%"} width={"80%"} />
                            <Skeleton height={"100%"} width={"80%"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-lg-none d-xl-none">
                <div className="pt-2">
                    <Skeleton height={30} width={"30%"} />
                    <div className="pt-3">
                        <Skeleton height={20} width={"50%"} />
                        <Skeleton height={20} width={"60%"} />
                    </div>
                </div>
                <div className="d-flex justify-content-center pt-3">
                    <div className="mr-2">
                        <Skeleton height={20} width={20} />
                    </div>
                    <div className="mx-1">
                        <Skeleton height={20} width={20} />
                    </div>
                    <div className="ml-2">
                        <Skeleton height={20} width={20} />
                    </div>
                </div>
                <div className="d-flex p-3">
                    <div className="d-flex">
                        <Skeleton circle={true} height={40} width={40} />
                        <div className="d-flex flex-column pt-1 pl-2">
                            <Skeleton height={10} width={20} />
                            <Skeleton height={10} width={20} />
                        </div>
                    </div>
                    <div className="ml-auto">
                        <Skeleton height={40} width={40} />
                    </div>
                    <div className="ml-2">
                        <Skeleton height={40} width={40} />
                    </div>
                </div>
                <div className="px-3">
                    <Skeleton height={300} width={"100%"} />
                </div>
                <div className="px-3 pt-2">
                    <Skeleton height={10} width={"100%"} />
                    <Skeleton height={10} width={"100%"} />
                    <Skeleton height={10} width={"100%"} />
                    <Skeleton height={10} width={"100%"} />
                    <Skeleton height={10} width={"100%"} />
                    <Skeleton height={10} width={"100%"} />
                    <Skeleton height={10} width={"100%"} />
                    <Skeleton height={10} width={"100%"} />
                    <Skeleton height={10} width={"100%"} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

export const ProfileLoader = () => {
    return (
        <>
            <div className="d-none d-xl-block d-lg-block">
                <SkeletonTheme>
                    <div className="d-flex flex-row justify-content-around" >
                        <div className="mr-4">
                            <Skeleton height={"60vh"} width={"40vh"} />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <Skeleton height={50} width={"49vh"} />
                                </div>
                                <div>
                                    <Skeleton height={50} width={"49vh"} />
                                </div>
                            </div>
                            <Skeleton height={150} width={"100vh"} />
                            <Skeleton height={150} width={"100vh"} />
                            <Skeleton height={150} width={"100vh"} />
                            <Skeleton height={150} width={"100vh"} />
                        </div>
                    </div>

                </SkeletonTheme>
            </div>
            <div>
                <SkeletonTheme>
                    <div className="d-flex flex-column">
                        <Skeleton circle={true} height={80} width={80} />
                        <Skeleton height={20} width={"50%"} />
                        <Skeleton height={20} width={"70%"} />
                        <div className="pt-3 w-100 border-bottom"></div>
                        <div className="pt-3 d-flex justify-content-around">
                            <Skeleton height={40} width={40} />
                            <Skeleton height={40} width={40} />
                        </div>
                        <div className="w-100 pt-3">
                            <Skeleton height={30} width={"100%"} />
                        </div>
                        <div className="w-100 border-bottom d-flex pt-3 pb-2">
                            <div className="mr-2">
                                <Skeleton height={30} width={60} />
                            </div>
                            <div>
                                <Skeleton height={30} width={60} />
                            </div>
                        </div>
                        <div className="w-100 pt-3">
                            <Skeleton height={80} width={"100%"} count={10} />
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
        </>
    )
}

export const ModalLoader = (props) => {
    return (
        <>
            <div className="d-none d-lg-block d-xl-block d-sm-none d-md-none">
                <SkeletonTheme>
                    <div role="button" className="close" data-dismiss="modal" aria-label="Close">
                        <img className="float-right" src={require('../../../assets/photo-icons/close.svg')} alt='close' />
                    </div>
                    <div className="d-flex">
                        <div className="d-flex align-items-center">
                            <button className="btn" style={{ border: "none", backgroundColor: "transparent", color: 'white' }}>
                                <FontAwesomeIcon icon='chevron-left' size='3x' />
                            </button>
                        </div>
                        <div className="row p-4 mx-5 w-100" style={{ height: "92vh", backgroundColor: "white", borderRadius: "5px" }} >
                            <div className="col-7 pl-0">
                                <Skeleton height={"86vh"} />
                            </div>
                            <div className="col-5 pr-0">
                                <div style={{ height: "18vh" }} className="d-flex row">
                                    <div className="d-flex flex-column col-9">
                                        <Skeleton height={"7vh"} width={"100%"} />
                                        <div className="d-flex pt-2 align-items-center">
                                            <Skeleton circle={true} height={60} width={60} />
                                            <div className="pl-2 d-flex flex-column">
                                                <Skeleton width={100} />
                                                <Skeleton width={100} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <Skeleton height={"16vh"} width={70} />
                                    </div>
                                </div>
                                <Skeleton height={"13vh"} />
                                <Skeleton height={"14vh"} />
                                <Skeleton height={"14vh"} />
                                <Skeleton height={"13vh"} />
                                <Skeleton height={"12vh"} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <button className="btn" style={{ border: "none", backgroundColor: "transparent", color: 'white' }}>
                                <FontAwesomeIcon icon='chevron-right' size='3x' />
                            </button>
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
            <div className="d-lg-none d-xl-none">
                <div style={{ height: props.height, backgroundColor: "#000000" }}>
                    <SkeletonTheme>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex pt-1 pl-2">
                                <Skeleton circle={true} height={40} width={40} />
                                <div className="d-flex flex-column pl-2 pt-1">
                                    <Skeleton height={10} width={20} />
                                    <Skeleton height={10} width={20} />
                                </div>
                            </div>
                            <div role="button" className="close pt-3 pr-3" data-dismiss="modal" aria-label="Close">
                                <img className="float-right" src={require('../../../assets/photo-icons/close.svg')} alt='close' />
                            </div>
                        </div>
                        <div className="pt-5">
                            <Skeleton height={"60vh"} width={"100%"} />
                        </div>
                        <div className="pl-3 pt-5">
                            <Skeleton height={20} width={150} />
                        </div>
                        <div className="p-3 d-flex justify-content-between w-100" style={{ position: 'absolute', bottom: 0 }}>
                            <Skeleton height={30} width={30} />
                            <Skeleton height={30} width={30} />
                            <Skeleton height={30} width={30} />
                            <Skeleton circle={true} height={30} width={30} />
                        </div>
                    </SkeletonTheme>
                </div>
            </div>
        </>
    );
}


export const PostLoader = () => {
    return (
        <SkeletonTheme color="#ececec">
            <div className="my-3">
                <Skeleton height={100} width={"100%"} />
            </div>
            <div className="my-3">
                <Skeleton height={100} width={"100%"} />
            </div>
            <div className="my-3">
                <Skeleton height={100} width={"100%"} />
            </div>
            <div className="my-3">
                <Skeleton height={100} width={"100%"} />
            </div>
            <div className="my-3">
                <Skeleton height={100} width={"100%"} />
            </div>
            <div className="my-3">
                <Skeleton height={100} width={"100%"} />
            </div>
            <div className="my-3">
                <Skeleton height={100} width={"100%"} />
            </div>
        </SkeletonTheme>
    )
}
export const FeaturedLoader = () => {
    return (
        <SkeletonTheme color="#ececec">
            <div className="d-flex justify-content-around p-3">
                <div className="mr-1">
                    <Skeleton width={350} height={350} />
                </div>
                <div className="mx-2">
                    <Skeleton width={350} height={350} />
                </div>
                <div className="ml-1">
                    <Skeleton width={350} height={350} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

export const UsersLoader = () => {
    return (
        <SkeletonTheme color="#ececec">
            <div className="d-flex flex-column" style={{ backgroundColor: "white" }}>
                <div className="p-5">
                    <Skeleton height={30} width={"100vh"} />
                    <Skeleton height={20} width={"120vh"} />
                </div>
                <div className="d-flex justify-content-around p-3">
                    <div className="d-flex flex-column">
                        <Skeleton circle={true} height={60} width={60} />
                        <Skeleton width={100} height={15} />
                        <Skeleton width={100} height={15} />
                    </div>
                    <div className="d-flex flex-column">
                        <Skeleton circle={true} height={60} width={60} />
                        <Skeleton width={100} height={15} />
                        <Skeleton width={100} height={15} />
                    </div>
                    <div className="d-flex flex-column">
                        <Skeleton circle={true} height={60} width={60} />
                        <Skeleton width={100} height={15} />
                        <Skeleton width={100} height={15} />
                    </div>
                    <div className="d-flex flex-column">
                        <Skeleton circle={true} height={60} width={60} />
                        <Skeleton width={100} height={15} />
                        <Skeleton width={100} height={15} />
                    </div>
                    <div className="d-flex flex-column">
                        <Skeleton circle={true} height={60} width={60} />
                        <Skeleton width={100} height={15} />
                        <Skeleton width={100} height={15} />
                    </div>
                    <div className="d-flex flex-column">
                        <Skeleton circle={true} height={60} width={60} />
                        <Skeleton width={100} height={15} />
                        <Skeleton width={100} height={15} />
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

export const DiscussLoader = () => {
    return (
        <SkeletonTheme color="#ececec">
            <div className="pt-5">
                <div className="float-left pb-3">
                    <Skeleton height={50} width={"30vh"} />
                </div>
                <div className="d-flex w-100 border-bottom mb-4 pb-4">
                    <div className="pr-3">
                        <Skeleton height={30} width={"20vh"} />
                    </div>
                    <div className="pr-3">
                        <Skeleton height={30} width={"20vh"} />
                    </div>
                    <div className="pr-3">
                        <Skeleton height={30} width={"20vh"} />
                    </div>
                </div>
            </div>
            <div className="">
                <div className="pb-2">
                    <Skeleton height={100} width={"100%"} />
                </div>
                <div className="pb-2">
                    <Skeleton height={100} width={"100%"} />
                </div>
                <div className="pb-2">
                    <Skeleton height={100} width={"100%"} />
                </div>
                <div className="pb-2">
                    <Skeleton height={100} width={"100%"} />
                </div>
                <div className="pb-2">
                    <Skeleton height={100} width={"100%"} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

export const RelatedLoader = () => {
    return (
        <SkeletonTheme>
            <div className="d-flex flex-column w-100 p-4" style={{ backgroundColor: "#f4f4f4" }}>
                <h1 className="text-center">Related Articles</h1>
                <div className="d-flex mx-4">
                    <button className="btn d-flex align-items-center">
                        <img src={require('../../../assets/common/scroll-left.svg')} alt="scroll" />
                    </button>
                    <div className="w-100 d-flex justify-content-between mx-4 my-2">
                        <div>
                            <Skeleton height={400} width={400} />
                        </div>
                        <div>
                            <Skeleton height={400} width={400} />
                        </div>
                        <div>
                            <Skeleton height={400} width={400} />
                        </div>
                    </div>
                    <button className="btn d-flex align-items-center">
                        <img src={require('../../../assets/common/scroll-right.svg')} alt="scroll" />
                    </button>
                </div>
            </div>
        </SkeletonTheme>
    )
}
export const ActivityLogLoader = () => {
    return (
        <div>
            <SkeletonTheme>
                <div className="p-3 d-flex flex-column" style={{ backgroundColor: '#f4f4f4' }}>
                    <Skeleton width={"100%"} height={50} count={15} />
                </div>
            </SkeletonTheme>
        </div>
    )
}