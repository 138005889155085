import axios from '../../axios';
import {
    GET_PHOTO, PHOTO_FETCHED, ADD_PHOTOS, SELECTED_PHOTO, PHOTO_SELECTED,
    CLEAR_GALLERY, PHOTO_COMMENT, PHOTO_COMMENTS, ADD_PHOTO_COMMENTS,
    PHOTO_COMMENTS_FETCHED, CREATE_PHOTO_VOTE, COMMENT_VOTES, TAGS, POINTS, UPDATE_PHOTO
} from './types';

export const loader = () => async dispatch => {
    try {
        dispatch({ type: PHOTO_FETCHED, payload: false })
    } catch (error) {

    }
}
export const getPhotos = (data, callback) => async dispatch => {
    try {
        let response = await axios.post("/photo/photos/" + data.tag, data);
        if (response.status === 200) {
            dispatch({ type: GET_PHOTO, payload: response.data.data });
            dispatch({ type: PHOTO_FETCHED, payload: true })
            callback(null)
        }
    } catch (error) {

    }
}

export const userPhotos = (data, callback) => async dispatch => {
    try {
        dispatch({ type: PHOTO_FETCHED, payload: false })
        let response = await axios.get("/photo/user/" + data);
        if (response.status === 200) {
            dispatch({ type: GET_PHOTO, payload: response.data.photos });
            dispatch({ type: PHOTO_FETCHED, payload: true })
        }
    } catch (error) {

    }
}


export const getPhotoTags = () => async dispatch => {
    try {
        let response = await axios.get('photo/tags');
        if (response.status === 200) {
            dispatch({ type: TAGS, payload: response.data.tags });
        }
    } catch (error) {

    }
}


export const uploadPhoto = (data, callback) => async dispatch => {
    try {
        const file = data.file;
        const formData = new FormData()
        formData.append('photo', file);
        formData.append('content', JSON.stringify(data.content));
        formData.append('color', JSON.stringify(data.color));
        formData.append('height', JSON.stringify(data.height));
        formData.append('tag', JSON.stringify(data.tag))
        let response = await axios.post('/photo/upload', formData);
        if (response.status === 200) {
            dispatch({ type: ADD_PHOTOS, payload: response.data.data })
            dispatch({ type: POINTS, payload: response.data.points })
            callback(null)
        }
    } catch (error) {
    }
}

export const selectPhoto = (data, callback) => async dispatch => {
    try {
        dispatch({ type: PHOTO_SELECTED, payload: false });
        let response = await axios.post('/photo/' + data.id, data);
        if (response.status === 200) {
            dispatch({ type: SELECTED_PHOTO, payload: response.data.data });
            dispatch({ type: PHOTO_COMMENTS, payload: response.data.photo_comments });
            dispatch({ type: CREATE_PHOTO_VOTE, payload: response.data.userVote })
            dispatch({ type: PHOTO_COMMENTS_FETCHED, payload: true })
            dispatch({ type: PHOTO_SELECTED, payload: true });
            callback(null)
        }
    } catch (error) {

    }
}
export const clearGallery = () => async dispatch => {
    try {
        dispatch({ type: CLEAR_GALLERY });
    } catch (error) {

    }
}
export const photoComment = (data) => async dispatch => {
    try {
        dispatch({ type: PHOTO_COMMENT, payload: data })
    } catch (error) {

    }
}


export const createComment = (data, callback) => async dispatch => {
    try {
        let id = data.id
        let response = await axios.post("/photo/comment/" + id, data);
        dispatch({ type: ADD_PHOTO_COMMENTS, payload: response.data.newcomment })
        dispatch({ type: POINTS, payload: response.data.points })
        callback(null)
    } catch (error) {

    }
}

export const createPhotoVote = (data, callback) => async dispatch => {
    try {
        let id = data.comment_id !== null ? data.comment_id : data.photo_id;
        let response = await axios.post("/photo/vote/" + id, data);
        if (response.status === 200) {
            dispatch({ type: POINTS, payload: response.data.points })
            if (response.data.photo) dispatch({ type: SELECTED_PHOTO, payload: response.data.photo });
            if (response.data.commentVotes) dispatch({ type: COMMENT_VOTES, payload: response.data.commentVotes });
            if (response.data.comments) dispatch({ type: PHOTO_COMMENTS, payload: response.data.comments });
            if (response.data.newVote && response.data.newVote.length !== 0) {
                dispatch({ type: CREATE_PHOTO_VOTE, payload: response.data.newVote.vote_type });
            } else {
                dispatch({ type: CREATE_PHOTO_VOTE, payload: 0 });
            }
            callback(null);
        }
    } catch (error) { }
}

export const updatePhoto = (data) => async dispatch => {
    try {        
        // eslint-disable-next-line no-unused-expressions
        data.type === 'vote' ? dispatch({ type: UPDATE_PHOTO, payload: { index: data.index, vote: data.vote, type: data.type,upvote :data.upvote,downvote :data.downvote } })
            : data.type === 'comment' ? dispatch({ type: UPDATE_PHOTO, payload: { index: data.index, commentCount: data.commentCount, type: data.type } })
                : null
    } catch (error) {

    }
}