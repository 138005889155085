import React from 'react';
import { lastUpdatedTime } from '../../common/timeAgo/timeAgo'
import './Notification.css';
import Notify from './notify'
export const NotificationDropdown = (props) => {
    return (
        props.data.length !== 0
            ?
            < div >
                <div className="drop-content">
                    {
                        props.data.slice(0, 10).map((notify, i) => {
                            return (
                                <div key={i} className="d-flex border-bottom mx-3 notification-content" onClick={() => props.dataHandler(notify.content_id._id, notify.type)}>
                                    <div>
                                        <img className="notification-user-thumbnail" src={notify.content_user.thumbnail} alt="" />
                                    </div>
                                    <div className="d-flex flex-column notify-content w-100">
                                        <div className="d-flex notify-section1">
                                            <div className="notify-user-name">
                                                {notify.content_user.u_userName ? notify.content_user.u_userName : null}
                                            </div>
                                            <div className="notify-time">
                                                {lastUpdatedTime(notify.created_at)}
                                            </div>
                                        </div>
                                        <div className="notify-type d-flex justify-content-between">
                                            <Notify type={notify.activity_id.type} content_user={notify.content_user} action_user={notify.created_by} content_type={notify.type} />
                                            <img src={require('../../../assets/common/right.svg')} alt="" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <button onClick={() => props.handler(true)} className="btn btn-notify w-100">Show all notifications</button>
            </div >
            :
            <div className="text-center p-2">No notifications yet</div>
    )
}