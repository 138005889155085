import React, { Component } from 'react';
import './vote.css'

class vote extends Component {
    state = {
        disabled: true,
        class: 'vote-active',
        vote: 0,
        upvote: 0,
        downvote: 0
    }
    VoteHandler = (id, vote) => {
        this.setState({ disabled: true })
        this.state.vote !== vote && vote === 1 ? this.setState({ vote: vote, upvote: this.state.upvote + 1 })
            : this.state.vote !== vote && vote === -1 ? this.setState({ vote: vote, upvote: this.state.downvote - 1 })
                : this.setState({ vote: 0, upvote: vote === 1 ? this.state.upvote - 1 : null, downvote: vote === -1 ? this.state.downvote - 1 : null })
        this.props.handler(id, vote)
    }
    componentDidMount() {
        if (localStorage.getItem('loginToken')) { this.setState({ disabled: false }) }
        this.setState({ vote: this.props.vote, upvote: this.props.data.upvote_count, downvote: this.props.data.downvote_count })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.vote !== this.props.vote) this.setState({ vote: this.props.vote, disabled: false })
        if (prevProps.data.upvote_count !== this.props.data.upvote_count) this.setState({ vote: this.props.data.upvote_count, disabled: false })
        if (prevProps.data.downvote_count !== this.props.data.downvote_count) this.setState({ vote: this.props.data.downvote_count, disabled: false })
    }
    render() {
        const { data, vote, height, width, font, vote_type, modalShowHandler } = this.props;
        return (
            <>
                <div className="d-flex flex-column  ml-auto vote-button">
                    <button data-toggle='tooltip' className={["button-vote ", this.state.vote === 1 ? 'vote-active' : null].join('')}
                        onClick={() => this.props.points < 0 ? modalShowHandler(true) : this.VoteHandler(data._id, 1)}
                        disabled={this.state.disabled}
                    >
                        <img className="p-1" src={this.state.vote === 1 ? require('../../../assets/common/active-up.svg') : require('../../../assets/common/vote-up.svg')} alt='upvote' style={{ height: height, minWidth: width, fill: "green" }} /></button>
                    <div className={vote_type === 'comment' ? 'comment-vote-count' : 'vote-post-count'} style={{ fontSize: font, padding: "3px 0px 3px 0px" }}>{this.state.upvote - (-this.state.downvote)}</div>
                    <button data-toggle='tooltip' className={["button-vote ", vote === -1 ? 'vote-active' : null].join('')}
                        onClick={() => this.props.points < 0 ? modalShowHandler(true) : this.VoteHandler(data._id, -1)}
                        disabled={this.state.disabled}
                    >
                        <img className="p-1" src={this.state.vote === -1 ? require('../../../assets/common/active-down.svg') : require('../../../assets/common/vote-down.svg')} alt='upvote' style={{ height: height, minWidth: width }} /></button>
                </div>
            </>
        );
    }
}




export default vote;
