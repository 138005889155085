import axios from 'axios'

const asyncValidate = async (values) => {
  let response = await axios.get('/auth/user-check');
  return response.data.userNames.map(value => {
    if (value.u_userName === values.u_userName) {
      throw { u_userName: 'That username is taken' }
    }
  })
}

export default asyncValidate