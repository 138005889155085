import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setUser } from './store/actions/users.actions'
import { getNotify, addNotification } from './store/actions/notification.action'
import { socket } from './socket.client'
import Homepage from './containers/routes'
import './App.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
class App extends Component {
    componentDidMount() {
        let user = localStorage.getItem("user");
        if (user) {
            this.props.setUser(user, (err) => {
                if (!err) {
                    socket.emit('room', this.props.u_id);
                    this.props.getNotify(this.props.u_id);
                }
            });
        }
        socket.on('new_notification', (data) => {            
            this.props.addNotification(data.data)

        })

    }
    render() {
        return (
            <Homepage />
        );
    }
}
const mapStateToProps = state => {
    return {
        token: state.authReducer.token,
        user: state.authReducer.currentUser,
        u_id: state.authReducer.currentUser && state.authReducer.currentUser._id
    }
};
const mapDispatchToProps = {
    setUser, getNotify, addNotification
}
export default connect(mapStateToProps, mapDispatchToProps)(App);