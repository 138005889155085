import axios from '../../axios'
import { GET_NOTIFICATIONS, NEW_NOTIFICATION, ADD_NOTIFICATION } from './types';
export const getNotify = (data) => async dispatch => {
    try {
        let response = await axios.get('/notification/user/' + data);
        if (response.status === 200) {
            dispatch({ type: GET_NOTIFICATIONS, payload: response.data.notifications })
        }
    } catch (error) {

    }
}
export const addNotification = (data) => async dispatch => {
    try {        
        dispatch({ type: ADD_NOTIFICATION, payload: data })
        dispatch({ type: NEW_NOTIFICATION, payload: true })
    }
    catch (error) {

    }
}
export const new_notification = (data) => async dispatch => {
    try {
        dispatch({ type: NEW_NOTIFICATION, payload: data })
    } catch (error) {

    }
}