import axios from 'axios';
if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = 'http://localhost:4000'
}
else {
  axios.defaults.baseURL  = 'https://api.fotogramam.com/'
}

// console.log('[Environment]=', process.env.NODE_ENV);
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('loginToken');
    if (token) {
      const item = { ...config };
      item.headers.Authorization = `Bearer ${token}`;
      return item; // replace original with new instance
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
export default axios;