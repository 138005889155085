export const lastUpdatedTime = (value) => {
    //   const value = timestamp.toDate();
    let delta;
    const timediff = (new Date().getTime() - new Date(value).getTime()) / 1000;
    if (timediff < 60) delta = { diff: Math.floor(timediff), format: 'second' };
    else if (timediff < 3600) { delta = { diff: Math.floor(timediff / 60), format: 'minute' }; } else if (timediff < 86400) { delta = { diff: Math.floor(timediff / 3600), format: 'hour' }; } else delta = { diff: Math.floor(timediff / 86400), format: 'day' };
    if (delta.format === 'second') {
        return 'Just now';
    } if (delta.format === 'minute') {
        if (delta.diff > 1) return `${delta.diff} minutes ago`;
        return '1 minute ago';
    } if (delta.format === 'hour') {
        if (delta.diff > 1) return `${delta.diff} hours ago`;
        return '1 hour ago';
    } if (delta.format === 'day') {
        if (delta.diff > 3) return new Date(value).toDateString();
        if (delta.diff > 1) return `${delta.diff} days ago`;
        return '1 day ago';
    }
    return '';
};