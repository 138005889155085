import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { postInput, textArea } from '../Field';
import { createPost, publish, tagRemoval, getTags, clearDraft, updateDraft, draftThumbnail } from '../../store/actions/post.actions';
import PropTypes from 'prop-types';
import Footer from '../shared/footer';
import Thumbnail from '../Field/thumbnail';
import Editor from '../common/contentEditor/editor';
import TypeAhead from '../common/typeAhead/typeAhead';
import 'react-toastify/dist/ReactToastify.css';
import './createPost.css';
import '../../styles.css';

const validate = values => {
    const errors = {}
    if (!values.title) {
        errors.title = 'Required'
    }
    if (!values.description) {
        errors.title = 'Required'
    }
    return errors
}

class CreatePost extends Component {

    state = {
        status: '',
        publishVisible: true,
        saveVisible: true,
        thumbnail: false
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'Create Post | FotoGramam';
        this.props.getTags();
        this.setState({ thumbnail: this.props.draft && this.props.draft.thumbnail ? true : false }, () => {
            this.props.draftThumbnail(true);
        })
    }
    statusHAndler = (status) => {
        this.setState({ status: status })
    }
    submitHandler = values => {
        this.state.status === 'published'
            ? this.setState({ publishVisible: false })
            : this.setState({ saveVisible: false })
        let date = new Date();
        let data = {
            date: date,
            thumbnail: this.props.thumbnail,
            title: values.title,
            description: values.description,
            tag: this.props.typeahead,
            posts: this.props.post_content,
            status: this.state.status
        }
        let darftData = {
            id: this.props.draft ? this.props.draft._id : null,
            date: date,
            thumbnail: this.props.thumbnail,
            draftThumbnail: this.props.draft_thumbnail,
            title: values.title,
            description: values.description,
            tag: this.props.typeahead,
            posts: this.props.post_content,
            status: this.state.status
        }
        this.props.draft ?
            this.props.updateDraft(darftData, (err) => {
                if (!err) {
                    this.props.history.push("/profile/" + this.props.user._id)
                }
                else {
                    this.setState({ disabled: true })
                }
            })
            :
            this.props.createPost(data, (err) => {
                if (!err) {
                    this.props.history.push("/profile/" + this.props.user._id)
                }
                else {
                    this.setState({ disabled: true })
                }
            });


    }
    componentWillUnmount() {
        this.props.tagRemoval();
        this.props.clearDraft();
        this.props.draftThumbnail(true);
    }
    exitHandler = () => {
        this.props.tagRemoval();
        this.props.history.push('/')
        this.props.draftThumbnail(true);
    }
    render() {
        const { handleSubmit, typeahead, post_content } = this.props;

        const isDisabled = () => {
            return !typeahead || !post_content || this.props.invalid
        };
        const isDraftDisabled = () => {
            return this.props.invalid;
        }
        return (
            <>
                <div className="container">
                    <div className="row mb-6">
                        <div className="col-12 p-0">
                            <div className="float-left mt-4 mb-2 ml-3 ml-xl-0 ml-lg-0 create">
                                Create a Post
                        </div>
                        </div>
                        <div className="col-12 form-style">
                            <form onSubmit={handleSubmit(this.submitHandler)} className="row mb-5">
                                <Field
                                    name="title"
                                    label="Title"
                                    className="form-control border-0 title mb-1 p-0"
                                    component={postInput}
                                    type="text"
                                    value={this.props.initialValues.title}
                                />
                                <Field
                                    label="Description"
                                    rows="4"
                                    name="description"
                                    className="border-0 description form-control mb-1 pl-1 pt-3"
                                    component={textArea}
                                    value={this.props.initialValues.description}
                                />
                                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 p-0 mb-4">
                                    <TypeAhead default={this.props.initialValues.tag} />
                                </div>
                                <div className="col-12 mb-4 p-0">
                                    <Thumbnail default={this.props.initialValues.thumbnail} />
                                </div>
                                <div className="col-12 p-0">
                                    <div className="d-flex flex-column">
                                        <div className="text-left content">
                                            Add content
                                    </div>
                                        <div className="form-group">
                                            <Editor post_content={post_content} default={this.props.initialValues.post_content} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 p-0">
                                    <div className="d-flex pt-3">
                                        {
                                            this.state.saveVisible ?

                                                <button
                                                    type="submit"
                                                    className="btn btn-draft"
                                                    onSubmit={handleSubmit(this.submitHandler)}
                                                    onClick={() => this.statusHAndler("draft")}
                                                    disabled={isDraftDisabled()}
                                                >
                                                    Save as Draft
                                                </button>
                                                :
                                                <button className="btn btn btn-draft" disabled>
                                                    SAVING ...
                                    </button>
                                        }
                                        <div className="ml-auto d-flex align-items-center">
                                            <div>
                                                <button className="btn btn-cancel mr-2"
                                                    onClick={this.exitHandler}>Cancel</button>
                                            </div>
                                            <div>
                                                {
                                                    this.state.publishVisible
                                                        ?
                                                        <button
                                                            className="btn btn-publish"
                                                            onSubmit={this.submitHandler}
                                                            onClick={() => this.statusHAndler("published")}
                                                            disabled={isDisabled()}                                                        >
                                                            Publish
                                                        </button>
                                                        : <button
                                                            className="btn btn-publish"
                                                            onClick={() => this.statusHAndler("published")}
                                                            disabled>
                                                            PUBLISHING ....
                                            </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
                </div>
                <Footer />
            </>
        );
    }
}
CreatePost.propTypes = {
    typeahead: PropTypes.string,
    post_content: PropTypes.string,

};
const mapStateToProps = state => {
    return {
        token: state.authReducer.token,
        user: state.authReducer.currentUser,
        thumbnail: state.photoReducer.thumbnail,
        typeahead: state.postReducer.typeahead,
        post_content: state.postReducer.postContent,
        draft: state.postReducer.draft,
        draft_thumbnail: state.postReducer.draftThumbnail,
        initialValues: {
            title: state.postReducer.draft && state.postReducer.draft.title,
            description: state.postReducer.draft && state.postReducer.draft.description,
            tag: state.postReducer.draft && state.postReducer.draft.tag,
            thumbnail: state.postReducer.draft && state.postReducer.draft.thumbnail,
            post_content: state.postReducer.draft && state.postReducer.draft.content
        }
    }
};

const mapDispatchToProps = {
    createPost,
    publish,
    tagRemoval,
    getTags,
    clearDraft,
    updateDraft,
    draftThumbnail
}
CreatePost = reduxForm({ form: 'CreatePost', validate: validate })(CreatePost);
export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);