import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { loader } from '../../store/actions/photo.actions'
import { getPhotos, selectPhoto, clearGallery, getPhotoTags } from '../../store/actions/photo.actions';
import { GalleryLoader } from '../shared/contentSkeletons/skeleton';
import StackGrid from "../shared/stackGrid/stackgrid";
import ReactHoverObserver from '../common/HoverDiv/hoverDiv';
// import Modal from '../shared/viewImage';
import { Bmodal } from '../shared/pointModal/point'
import Pmodal from '../shared/viewImage/picModal'
// import { MasonryLayout } from '../common/masonryLoad/masonryLoad'
import './photos.css'

class Photos extends Component {
    state = {
        initialPage: 1,
        pageSize: this.props.data,
        loading: false,
        limit: 15,
        pageNo: 0,
        count: 6,
        nextCall: false,
        height: window.innerHeight,
        message: 'not at bottom',
        visible: false,
        photos: [],
        isOpen: true,
        isPointOpen: false,
        loader: false,
        disabled: '',
        windowHeight: window.innerHeight
    }
    _isMounted = false
    componentDidMount() {
        this._isMounted = true;
        document.title = 'Photos | Fotogramam';
        this.props.history.push("/photos/" + this.props.match.params.tag);
        this.props.getPhotoTags()
        this.props.loader();
        this.props.clearGallery();
        this.timer = setTimeout(() => {
            this.loaddata();
        }, 2000);
        window.addEventListener('scroll', this.loadMore);
        window.addEventListener('resize', this.resizeWindow)
        this.setState({ window: window.innerWidth })
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.loadMore);
        this._isMounted = false;
        clearTimeout(this.timer)
    }
    photoSelectHandler = (id) => {
        let data = {
            id: id,
            user: this.props.user
        }
        this.props.selectPhoto(data, (err) => {
            if (!err) {
                this.props.history.push("/photos/" + this.props.match.params.tag)
            }
        })

    }
    loaddata() {
        let data = { limit: 15, pageNo: 0, tag: this.props.match.params.tag, user: this.props.user };
        this.props.getPhotos(data, (err) => {
            if (!err && this._isMounted) {
                this.setState({ nextCall: true, pageNo: this.state.pageNo + 1, scrollable: true, photos: this.props.data })
            }
        })
    }
    getTags = (type) => {
        this.props.getPhotoTags();
    }
    getTagPosts = (tag) => {
        this.props.clearGallery();
        this.setState({ disabled: 'active-null' })
        this.props.history.push("/photos/" + tag);
        this.props.loader();
        this.setState({ pageNo: 0 })
        let data = { limit: 15, pageNo: 0, tag: tag }
        this.props.getPhotos(data, (err) => {
            if (!err && this._isMounted) {
                this.setState({ nextCall: true, pageNo: this.state.pageNo + 1, photos: this.props.data, disabled: '' })
            }
        })
    }
    loadMore = () => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const docHeight = body.scrollHeight
        const windowBottom = windowHeight + window.pageYOffset;
        if ((Math.floor(windowBottom) >= (docHeight - 1)) && this.state.nextCall && this.props.auth) {
            this.setState({ loading: true, nextCall: false });
            let data = { limit: this.state.limit, pageNo: this.state.pageNo, tag: this.props.match.params.tag, user: this.props.user }
            this.props.getPhotos(data, (err) => {
                if (!err && this._isMounted) {
                    this.setState({ pageNo: this.state.pageNo + 1, loading: false, nextCall: true, visible: true, photos: this.props.data });
                }
                else {
                    this.setState({ loading: false })
                }
            })
        }

    }
    resizeWindow = () => {
        this.setState({ windowHeight: window.innerHeight })
    }
    showModal = (show) => {
        this.setState({ isOpen: !show, isPointOpen: show })
    };
    closeModal = (show) => {
        this.setState({ isOpen: show, loader: show })
    }
    showPointModal = (pointShow) => {
        this.setState({ isPointOpen: pointShow, isOpen: !pointShow })
    }
    render() {
        const { data, auth, photo_selected } = this.props;
        let photos = (
            this._isMounted ?
                data.map((photo, i) => {
                    return (
                        < ReactHoverObserver modalHandler={this.closeModal} key={i} photo={photo.photo} commentCount={photo.commentCount} userVoted={photo.userVoted} handler={this.photoSelectHandler} />
                    );
                })
                : null
        )
        let tagbar = (
            <div className="d-flex tag-contents" style={{ overflowX: 'scroll', height: '100%', paddingBottom: '1rem' }}>
                {this.props.tags.map((tag, i) => {
                    return (
                        <NavLink exact to={'/photos/' + tag} key={i} className={["tag btn text-nowrap text-capitalize position-relative", this.state.disabled].join(' ')} onClick={() => { this.getTagPosts(tag) }}>
                            {tag}
                        </NavLink>
                    )
                })}
            </div>
        )
        return (
            <div className="position-relative">
                <div className="d-flex w-100 position-sticky tag-bar">
                    <div className="d-flex pl-0 w-100" >
                        <NavLink to="/photos/all" style={{ marginBottom: '1rem' }} className=" tag btn text-nowrap text-capitalize position-relative" onClick={() => { this.getTagPosts('all') }}>ALL</NavLink>
                        <div className="pipe d-flex" style={{ marginBottom: '33px' }}>|</div>
                        {tagbar}
                    </div>
                </div>
                <div className="container scrolls" style={{ minHeight: "100vh" }}>
                    {
                        auth && this._isMounted ?
                            <>
                                <StackGrid data={photos} stackWidth='33%' />
                                {/* <MasonryLayout columns={3} gap={25}>
                                    {photos}
                                </MasonryLayout> */}
                            </>
                            :
                            <GalleryLoader />
                    }
                </div>
                {/* <Modal photo={photo_selected} handler={this.showModal} /> */}
                {
                    this.state.loader
                        ? <Pmodal show={this.state.isOpen} onHide={() => this.closeModal(false)} handler={this.showModal} photo={photo_selected} windowHeight={this.state.windowHeight} />
                        : null
                }

                <Bmodal show={this.state.isPointOpen} onHide={() => this.showPointModal(false)} />

            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.photoReducer.photo_fetched,
        data: state.photoReducer.photos,
        recent: state.photoReducer.photos && state.photoReducer.photos[0],
        photo_selected: state.photoReducer.selected_photo,
        trending: state.photoReducer.trending,
        user: state.authReducer.currentUser && state.authReducer.currentUser._id,
        tags: state.postReducer.items
    }
};

const mapDispatchToProps = {
    getPhotos,
    loader,
    selectPhoto,
    clearGallery,
    getPhotoTags
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Photos));