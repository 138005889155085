import React from 'react';
import './modal.css'

class Modal extends React.Component {
    render() {
        return (
            <div className="modal fade" role="dialog" id="exampleModalCenter" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="d-flex justify-content-center main-div content-card">
                            <div className="card shadow position-relative">
                                <div className="face-div position-absolute">
                                    <img className="face" src={require('../../../assets/common/face.svg')} alt="oops!" />
                                </div>
                                <div className="position absolute">
                                    <div className="text-center msg">OOPS !</div>
                                    <div className="msg-contents">You are out of points ! To continue ensure you have sufficent points</div>
                                </div>
                                <div className="modal-buttons mt-auto mb-5 text-center">
                                    <button className="btn btn-success mr-4 buy">BUY POINTS</button>
                                    <button className="btn cancel" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;