import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import '../../../styles.css'
import { NavLink } from 'react-router-dom';

class NavModal extends Component {
    render() {
        const { show, onHide } = this.props;
        return (
            <Modal show={show} onHide={onHide} className="navModal" size="xl" aria-labelledby="contained-modal-title-vcenter">
                <div role="button" onClick={() => onHide(false)}>
                    <img className="m-4" style={{ cursor: 'pointer' }} src={require('../../../assets/photo-icons/close.svg')} alt='close' />
                </div>
                <div className="d-flex flex-column align-items-center mt-5">
                    <NavLink to="/home" className="btn btn-navbar nav-modal-text" id='#nav-item' onClick={() => onHide(false)}>Home</NavLink>
                    <NavLink to="/photos" className="btn btn-navbar nav-modal-text mt-3" onClick={() => onHide(false)}>Photos</NavLink>
                </div>
            </Modal>

        );
    }
}


export default NavModal;
