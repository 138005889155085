import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectPhoto } from '../../../store/actions/photo.actions'
import { GalleryLoader } from '../contentSkeletons/skeleton';
// import { MasonryLayout } from '../../common/masonryLoad/masonryLoad';
import HoverDiv from '../../common/HoverDiv/hoverDiv';
import StackGrid from '../stackGrid/stackgrid';
import Pmodal from '../viewImage/picModal'

class Gallery extends Component {
    state = {
        isOpen: false
    }
    photoSelectHandler = (id) => {
        let data = {
            id: id,
            user: this.props.user
        }
        this.props.selectPhoto(data)
    }
    closeModal = (show) => {
        this.setState({ isOpen: show })
    }
    render() {
        const { photos, auth } = this.props;

        let gallery = (
            photos.length !== 0
                ?
                photos.map((photo, i) => {
                    return (
                        <HoverDiv key={i} modalHandler={this.closeModal} photo={photo.photo} commentCount={photo.commentCount} userVoted={photo.userVoted} handler={this.photoSelectHandler} />
                    );
                })
                : <div>No photos yet</div>
        );
        return (
            auth
                ?
                <div className="p-2">
                    <StackGrid data={gallery} stackWidth='33%' />
                    {/* <MasonryLayout columns={3} gap={25}>
                        {gallery}
                    </MasonryLayout> */}
                    <Pmodal show={this.state.isOpen} onHide={() => this.closeModal(false)} handler={this.showModal} />
                </div>
                :
                <div className="px-4">
                    <GalleryLoader />
                </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        photos: state.photoReducer.photos,
        auth: state.photoReducer.photo_fetched,
        photoSelected: state.photoReducer.selected_photo
    }
};

const mapDispatchToProps = {
    selectPhoto
}
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);