import React, { Component } from 'react';

class footer extends Component {
    render() {
        return (
            <footer className="mt-4 page-footer font-small text-light" style={{backgroundColor:'black'}}>
                <div className="footer-copyright text-center py-3">© 2020 Copyright:
                <a className="text-light" href="https://dev.fotogramam.com/"> FotoGramam.com</a>
                </div>
            </footer>

        );
    }
}

export default footer;