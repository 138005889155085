import React, { Component } from 'react';
import { createPhotoVote, updatePhoto } from '../../../store/actions/photo.actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './index.css'

class vote extends Component {
    state = {
        disable: false,
        class: 'vote-active',
        vote: 0,
        upvote: 0,
        downvote: 0,
        index: null
    }
    componentDidMount() {
        if (this.props.data.uploaded_by._id === this.props.u_id) this.setState({ disable: true })
        this.setState({ upvote: this.props.data.upvote_count, downvote: this.props.data.downvote_count, vote: this.props.vote, index: this.props.index })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.vote !== this.props.vote) { this.setState({ vote: this.props.vote }) }
        if (prevProps.data.upvote_count !== this.props.data.upvote_count) { this.setState({ upvote: this.props.data.upvote_count }) }
        if (prevProps.data.downvote_count !== this.props.data.downvote_count) { this.setState({ upvote: this.props.data.downvote_count }) }
    }
    update = () => {
        this.props.updatePhoto({ index: this.state.index, vote: this.state.vote === (1 || -1) ? true : false, type: 'vote', upvote: this.state.upvote, downvote: this.state.downvote })
    }
    voteHandler = (vote) => {
        this.setState({ disable: true })
        this.state.vote !== vote && vote === 1 ? this.setState({ vote: vote, upvote: this.state.upvote + 1 }, () => this.update())
            : this.state.vote !== vote && vote === -1 ? this.setState({ vote: vote, upvote: this.state.downvote - 1 }, () => this.update())
                : this.setState({ vote: 0, upvote: vote === 1 ? this.state.upvote - 1 : null, downvote: vote === -1 ? this.state.downvote - 1 : null }, () => this.update())
        let data = { comment_id: null, photo_id: this.props.data._id, vote: vote, type: this.props.type, index: this.state.index }
        this.props.createPhotoVote(data, (err) => {
            if (!err) {
                this.setState({ disable: false })
            }
        })
    }
    render() {
        const { points, modalHandler } = this.props;
        return (
            <div className="d-flex flex-xl-column flex-lg-column ml-xl-auto ml-lg-auto vote-section">
                <button className={["mr-5 mr-xl-0 mr-lg-0 btn btn-sm btn-vote-photo ", this.state.vote === 1 ? 'vote-active' : "border"].join(' ')}
                    onClick={() => points < 0 ? modalHandler(true) : this.voteHandler(1)}
                    disabled={this.state.disable}>
                    <img className="photo-vote-sign" src={this.state.vote === 1 ? require('../../../assets/common/active-up.svg') : require('../../../assets/photo-icons/vote.svg')} alt='upvote' /></button>
                <div className="py-1 photo-vote-count d-none d-lg-block d-xl-block d-sm-none d-md-none" style={{ fontSize: "12px" }}>{this.state.upvote - (-this.state.downvote)}</div>
                <button className={["ml-auto ml-xl-0 ml-lg-0 btn btn-sm btn-vote-photo", this.state.vote === -1 ? 'vote-active' : 'border'].join(' ')}
                    onClick={() => points < 0 ? modalHandler(true) : this.voteHandler(-1)}
                    disabled={this.state.disable}>
                    <img className="photo-vote-sign" src={this.state.vote === -1 ? require('../../../assets/common/active-down.svg') : require('../../../assets/photo-icons/vote2.svg')} alt='upvote' /></button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.photoReducer.photo_selected,
        points: state.authReducer.points,
        user: state.authReducer.currentUser,
        u_id: state.authReducer.currentUser && state.authReducer.currentUser._id

    }
};

const mapDispatchToProps = {
    createPhotoVote,
    updatePhoto
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(vote));
