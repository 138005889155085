import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { myInput } from '../Field';
import { login } from '../../store/actions/auth.actions';
import '../../styles.css';

class signin extends Component {
    state = {
        error: '',
        disabled: false
    }
    submitHandler = values => {
        this.setState({ disabled: true })
        this
            .props
            .login(values, (err) => {
                if (err) {
                    this.setState({ error: err, disabled: false })
                } else if (!err) {

                    this
                        .props
                        .history
                        .push("/");
                }
            });
    }
    componentDidMount() {
        document.title = 'Signin | Fotogramam';
        if (localStorage.getItem('registerToken') || localStorage.getItem('loginToken')) {

            this
                .props
                .history
                .push("/");
        }
    }

    render() {
        let error = (
            <div
                style={{
                    backgroundColor: "tomato",
                    color: "white"
                }}>{this.state.error}</div>
        );
        const { handleSubmit } = this.props;
        return (
            <main className="d-flex flex-column">
                <div>
                    {error}
                    <NavLink role="button" className="btn float-left my-3 mx-4" to='/'>
                        <FontAwesomeIcon icon="arrow-left" size='2x' color="grey" />
                    </NavLink>
                </div>

                <section className="d-flex align-items-center my-5">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-8 col-md-6 col-lg-6 offset-md-1 order-md-2">
                                <img
                                    src={require('../../assets/svg/signin.svg')}
                                    alt="..."
                                    className="img-fluid" />
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 order-md-1">
                                <div>
                                    <div className="text-center text-md-center mt-4 mt-md-0">
                                        <h2 className="font-weight-normal">WELCOME BACK!</h2>
                                        <p className="text-gray mb-0">Please log back in to access your account.</p>
                                    </div>
                                    <div className="btn-wrapper mt-4 mb-5 text-center"></div>
                                    <span className="clearfix"></span>
                                    <form onSubmit={handleSubmit(this.submitHandler)}>
                                        <Field
                                            name="u_email"
                                            icon="user"
                                            className="form-control border-left-0"
                                            component={myInput}
                                            type="email"
                                            placeholder="Enter email"
                                            required="yes" />
                                        <Field
                                            name="u_password"
                                            icon="unlock-alt"
                                            required="yes"
                                            className="form-control border-left-0 border-right-0"
                                            component={myInput}
                                            type="password"
                                            placeholder="Password" />
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="btn btn-block btn-primary animate-up-2"
                                                onSubmit={handleSubmit(this.submitHandler)}>Sign in</button>
                                        </div>
                                    </form>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <span>
                                            <small>Not registered?</small>
                                            <NavLink to="/auth/signup" className="small font-weight-bold ml-1   ">Create account</NavLink>
                                        </span>
                                        <div>
                                            <NavLink to="/auth/forgot-password" className="small text-right ">Lost password?</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
const mapStateToProps = state => {
    return { token: state.authReducer.token }
};

const mapDispatchToProps = {
    login
}

signin = reduxForm({ form: 'login' })(signin);
export default connect(mapStateToProps, mapDispatchToProps)(signin);