import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { selectPhoto } from '../../../store/actions/photo.actions';
import { createComment } from '../../../store/actions/photo.actions'
import { withRouter } from 'react-router-dom';
import { lastUpdatedTime } from '../../common/timeAgo/timeAgo';
import { ModalLoader } from '../contentSkeletons/skeleton'
import Modal from "react-bootstrap/Modal";
import CreateComment from '../../common/comment/createComment';
import Comments from '../../common/comment/comment';
import Vote from './vote';
import './index.css'
class Pmodal extends Component {
    state = {
        new_comment: false,
        photo: null,
        index: null,
        isOpen: false,
        isDetailed: false,
        isCommented: false
    }
    newCommentHandler = (value) => {
        this.setState({ new_comment: value })
        setTimeout(() => { this.setState({ new_comment: false }) }, 3000);
    }
    componentDidUpdate(prevProps) {
        if (this.props.photo !== prevProps.photo) {
            this.props.photos.map((photo, i) => {
                return (
                    photo.photo._id === this.props.photo._id ?
                        this.setState({ index: i })
                        : null
                )
            })
            this.setState({ photo: this.props.photo })
        }
    }
    contentChangeHandler = (value) => {
        this.setState({ index: this.state.index + value }, () => {
            this.props.photos.map((photo, i) => {
                let data = { id: photo.photo._id, user: this.props.user ? this.props.user._id : null }
                return (i === this.state.index ? this.props.selectPhoto(data) : null)
            })
        })

    }
    createComment = (comment) => {
        let data = {
            id: this.props.data._id,
            comment: comment,
        }
        this.props.createComment(data)
    }
    modalShowHandler = (prop) => {
        this.props.handler(prop)
    }
    render() {
        const { comments, data, auth, photo_vote, u_id, comments_fetched, onHide, show, windowHeight } = this.props;
        return (
            <Modal show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" >
                {auth && comments_fetched ?
                    <>
                        <div className="d-none d-lg-block d-xl-block d-sm-none d-md-none">
                            <div role="button" onClick={() => onHide(false)}>
                                <img className="float-right" style={{ cursor: 'pointer' }} src={require('../../../assets/photo-icons/close.svg')} alt='close' />
                            </div>
                            <div className="d-flex" style={{ height: "93vh" }}>
                                <div className=" d-flex align-items-center navigation" onClick={() => this.contentChangeHandler(-1)}>
                                    <FontAwesomeIcon icon="chevron-left" size='3x' />
                                </div>
                                <div className="row border w-100 p-4 mx-lg-5" style={{ backgroundColor: "white", borderRadius: "5px" }}>
                                    <div className="col-8 padding-0">
                                        <img src={data.img_url} alt="modal images" style={{ height: "88vh", width: "100%", objectFit: 'cover', borderRadius: "5px" }} />
                                    </div>
                                    <div className="col-4 w-100 pr-0">
                                        <div className="pb-4">
                                            <div className="d-flex flex-row">
                                                <div className="d-flex flex-column ">
                                                    <div className="d-flex flex-column h-100">
                                                        <div className="photo-content">
                                                            {data.content}
                                                        </div>
                                                        <div className="d-flex mt-auto">
                                                            {
                                                                data.uploaded_by.thumbnail
                                                                    ? <img className="user-profile" src={data.uploaded_by.thumbnail} alt="profile" />
                                                                    : <img className="user-profile" src={require('../../../assets/feed_icons/profile.svg')} alt="profile" />
                                                            }
                                                            <div>
                                                                <div className="user-name">
                                                                    {data.uploaded_by.u_userName}                                                                        </div>
                                                                <div className="text-left user-time" style={{ fontSize: "10px" }}>
                                                                    <small>{lastUpdatedTime(data.created_at)}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Vote data={data} type={"photo"} vote={photo_vote} modalHandler={this.modalShowHandler} index={this.state.index} />
                                            </div>
                                        </div>
                                        <div className="p-2 d-flex flex-column comment-section" >
                                            <div className="p-3">
                                                <CreateComment rows={4} user={u_id} handler={this.createComment} type='photo' modalHandler={this.modalShowHandler} />
                                            </div>
                                            <div className="photo-comments p-3" >
                                                <Comments comments={comments} type='photo' photo_id={data._id} modalHandler={this.modalShowHandler} index={this.state.index} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex align-items-center navigation" onClick={() => this.contentChangeHandler(1)}>
                                    <FontAwesomeIcon icon="chevron-right" size='3x' />
                                </div>
                            </div>
                        </div>
                        <div className="d-lg-none d-xl-none w-100 p-xl-4 p-lg-4 mx-lg-5" style={{ backgroundColor: "black", height: windowHeight }}>
                            <div className="d-flex flex-row justify-content-between pt-3 px-2 pb-5 align-items-center">
                                <div className="d-flex mt-auto">
                                    {
                                        data.uploaded_by.thumbnail
                                            ? <img className="user-profile" src={data.uploaded_by.thumbnail} alt="profile" />
                                            : <img className="user-profile" src={require('../../../assets/feed_icons/profile.svg')} alt="profile" />
                                    }
                                    <div>
                                        <div className="user-name">
                                            {data.uploaded_by.u_userName}</div>
                                        <div className="text-left user-time" style={{ fontSize: "10px" }}>
                                            <small>{lastUpdatedTime(data.created_at)}</small>
                                        </div>
                                    </div>
                                </div>
                                <div role="button" onClick={() => onHide(false)}>
                                    <img className="float-right" style={{ cursor: 'pointer', height: "20px", width: "20px" }} src={require('../../../assets/photo-icons/close.svg')} alt='close' />
                                </div>
                            </div>
                            <div className="padding-0">
                                <img src={data.img_url} alt="modal images" style={{ height: "60vh", width: "100%", objectFit: 'cover' }} />
                            </div>
                            <div className="p-3 w-100" style={{ position: 'absolute', bottom: 0 }}>
                                <div className="photo-content pb-3">
                                    {data.content}
                                </div>
                                <div className='d-flex content-details pb-3'>
                                    <div className="mr-2">{data.upvote_count + data.downvote_count}&nbsp;votes</div>
                                    <div className="ml-2">{comments.length}&nbsp;comments</div>
                                </div>
                                <div className="d-flex">
                                    <Vote data={data} type={"photo"} vote={photo_vote} modalHandler={this.modalShowHandler} index={this.state.index} />
                                    <img className="ml-auto border" style={{ borderRadius: "0.2rem", padding: "0px 10px 0 10px" }} src={require('../../../assets/photo-icons/comment.svg')} alt="" onClick={() => this.setState({ isCommented: !this.state.isCommented })} />
                                    <img className="ml-auto" style={{ height: "30px", width: "30px" }} src={require('../../../assets/photo-icons/details.svg')} alt="" onClick={() => this.setState({ isDetailed: !this.state.isDetailed })} />
                                </div>
                                {
                                    this.state.isDetailed ?
                                        <div className="detail-hover" style={{ height: windowHeight }}>
                                            <div role="button" className="pr-2 pt-4" onClick={() => this.setState({ isDetailed: false })}>
                                                <img className="float-right" style={{ cursor: 'pointer', height: "20px", width: "20px" }} src={require('../../../assets/photo-icons/cancel.svg')} alt='close' />
                                            </div>
                                            <div className="text-center" style={{ color: "white", fontSize: "20px" }}>
                                                Info
                                            </div>
                                            <div className="px-3 pt-4">
                                                <div>
                                                    <div style={{ color: "white", fontSize: "14px" }}>Title</div>
                                                    <div style={{ color: "white", fontSize: "18px" }}>{data.content}</div>
                                                </div>
                                                <div>
                                                    <div style={{ color: "white", fontSize: "14px" }}>Description</div>
                                                    <div style={{ color: "white", fontSize: "18px" }}>not yet</div>
                                                </div>
                                                <div>
                                                    <div style={{ color: "white", fontSize: "14px" }}>upvotes</div>
                                                    <div style={{ color: "white", fontSize: "18px" }}>{data.upvote_count}</div>
                                                </div><div>
                                                    <div style={{ color: "white", fontSize: "14px" }}>downvote</div>
                                                    <div style={{ color: "white", fontSize: "18px" }}>{data.downvote_count}</div>
                                                </div><div>
                                                    <div style={{ color: "white", fontSize: "14px" }}>Publish</div>
                                                    <div style={{ color: "white", fontSize: "18px" }}>{lastUpdatedTime(data.created_at)}</div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {this.state.isCommented ?
                                    <div style={{ backgroundColor: "white", height: windowHeight, position: 'absolute', bottom: 0, left: 0, width: "100%" }}>
                                        <div className="d-flex align-items-center justify-content-center py-2" style={{ boxShadow: "0px 0px 4px 0px rgba(0,0,0,.15)", borderRadius: "3px" }}>
                                            <div><strong>Comments</strong></div>
                                            <button className="btn" style={{ position: 'absolute', right: 0 }} onClick={() => this.setState({ isCommented: false })}>Done</button>
                                        </div>
                                        <div className="p-3">
                                            <CreateComment rows={4} user={u_id} handler={this.createComment} type='photo' modalHandler={this.modalShowHandler} />
                                        </div>
                                        <div className="photo-comments p-3" >
                                            <Comments comments={comments} type='photo' photo_id={data._id} modalHandler={this.modalShowHandler} index={this.state.index} />
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>

                        </div>
                    </> : <ModalLoader height={windowHeight} />
                }
            </Modal>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth: state.photoReducer.photo_selected,
        photos: state.photoReducer.photos,
        data: state.photoReducer.selected_photo,
        comments: state.photoReducer.comments,
        comments_fetched: state.photoReducer.comments_fetched,
        photo_vote: state.photoReducer.userPhotoVote,
        comment_votes: state.photoReducer.comment_votes,
        user: state.authReducer.currentUser,
        u_id: state.authReducer.currentUser && state.authReducer.currentUser._id,
        points: state.authReducer.points
    }
};

const mapDispatchToProps = {
    createComment, selectPhoto
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pmodal));