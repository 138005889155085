import React from 'react'
import {Route, Redirect} from 'react-router-dom';

const privateRoute = ({
    component: Component,
    ...rest
}) => {
    let token = localStorage.getItem('loginToken');
    return (
        <Route
            {...rest}
            render={props => token
            ? <Component {...props}/>
            : <Redirect to="auth/login"/>}/>
    )
}
export default privateRoute