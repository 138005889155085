import {
    ALL_USERS, USERS_FETCHED, USER_PROFILE, USER_PROFILE_FETCHED,
    PROFILE_FETCHED, FETCH_DATA, USER_POSTS, USER_PHOTOS, USER_LOG, LOG_FETCHED,
} from "../actions/types";

const initialState = {
    // users session
    usersData: null,
    usersFetched: false,
    // single user session
    userProfile: null,
    userProfileFetched: false,
    userPosts: null,
    userPhotos: null,
    // profile session
    fetchdata: null,
    profilefetched: false,
    // log section
    userLog: [],
    logFetched: false
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        // users data session
        case ALL_USERS:
            return {
                ...state,
                usersData: action.payload
            }
        case USERS_FETCHED:
            return {
                ...state,
                usersFetched: action.payload
            }
        // single user data
        case USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload
            }
        case USER_PROFILE_FETCHED:
            return {
                ...state,
                userProfileFetched: true
            }
        // profile session
        case PROFILE_FETCHED:
            return {
                ...state,
                profilefetched: action.payload
            }
        case FETCH_DATA:
            return {
                ...state,
                fetchdata: action.payload
            }
        case USER_POSTS:
            return {
                ...state,
                userPosts: action.payload
            }
        case USER_PHOTOS:
            return {
                ...state,
                userPhotos: action.payload
            }
        case USER_LOG:
            let logArray = action.payload;
            return {
                ...state,
                userLog: logArray
            }
        case LOG_FETCHED:
            return {
                ...state,
                logFetched: action.payload
            }
        default:
            return state
    }
}
export default userReducer;