import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LogMessage from './logComment';
import { ActivityLogLoader } from '../contentSkeletons/skeleton'
import { lastUpdatedTime } from '../../common/timeAgo/timeAgo'
import Modal from "react-bootstrap/Modal";
import './log.css';


class log extends Component {
    setShow = (bool) => {
        this.props.handler(bool)
    }
    redirectHandler = (id, type) => {
        this.props.redirect(id, type)
        this.props.handler(false);
    }
    render() {
        const { show, auth, logs, onHide } = this.props;
        return (
            <div>
                <Modal
                    show={show}
                    onHide={() => this.setShow(false)}
                    dialogClassName="log-modal"
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <div className="d-flex">
                        <div className="w-100">
                            <div className="log-header d-flex align-items-center">
                                <div className="log-header-text">User Log Activities</div>
                                <img className="d-lg-none d-xl-none" onClick={() => onHide(false)} style={{ cursor: 'pointer', height: "20px", width: "20px" }} src={require('../../../assets/common/close.svg')} alt='close' />
                            </div>
                            {auth
                                ?
                                <div className="log-body">
                                    <Modal.Body>
                                        {
                                            logs.map((log, i) => {
                                                return (
                                                    <div key={i} className="d-flex my-2 log justify-content-between">
                                                        <div className="d-flex align-items-center p-2 ">
                                                            <img className="post-log-thumbnail" src={log.content.thumbnail ? log.content.thumbnail : log.content.img_url ? log.content.img_url : "https://www.hawtcelebs.com/wp-content/plugins/special-recent-posts-pro/images/no-thumb.png"} alt="post-log-thumbnail" />
                                                            <div>
                                                                <LogMessage type={log.activity} content_user={log.content_user} action_user={log.activity_user} content_type={log.content_type} handler={this.redirectHandler} />
                                                                <div className="msg-time">{lastUpdatedTime(log.activity_time)}</div>
                                                            </div>
                                                        </div>
                                                        <div className="log-points d-flex flex-column text-center p-2">
                                                            <div className="log-nume">{Math.sign(log.point) === -1 ? -(log.point) : log.point}</div>
                                                            <div className="log-point-text">Points</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Modal.Body>
                                </div>
                                : <ActivityLogLoader />
                            }
                        </div>
                        <div role="button" className="close-button d-none d-xl-block d-lg-block" onClick={() => onHide(false)}>
                            <img className="float-right" style={{ cursor: 'pointer' }} src={require('../../../assets/photo-icons/close.svg')} alt='close' />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        logs: state.userReducer.userLog,
        auth: state.userReducer.logFetched,
    }
};

const mapDispatchToProps = {

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(log));
