import {
    TAG_CREATION, TAG_REMOVAL,
    CLEAR_TAGS, GET_POST, DRAFT_REMOVAL, DRAFT_FETCHED, DRAFT_STATUS,
    POST_SELECTED, COMMENTS, ADD_COMMENTS, CREATE_VOTE, CLEAR_ALL_DATA,
    CLEAR_COMMENTS, COMMENT_COUNT, TYPEAHEAD, TAGS, POST_CONTENT,
    DISCUSS, DISCUSS_FETCHED, ALL_POSTS, ALL_POSTS_FETCHED, TREND_FETCHED,
    TRENDING_POSTS, CLEAR_DISCUSS, CREATE_COMMENT, RELATED_POST, GET_COMMENT_COUNT, CLEAR_DRAFT, DRAFT_THUMBNAIL, USER_PROFILE_POSTS, DISCUSS_COMMENT_COUNT, CLEAR_COMMENT_COUNT
} from '../actions/types';
const initialState = {
    // data session
    allposts: [],
    trending: [],
    trendFetched: false,
    discuss: [],
    discussPostCommentCount: [],
    discuss_fetched: false,
    posts_fetched: false,
    userProfilePosts: [],
    postCounter: null,
    counter: 0,

    // typeahead
    typeahead: null,

    // post content
    postContent: null,
    postCommentCount: 0,

    // tags session
    items: [],
    focused: false,
    input: '',

    // post session
    selectedPost: null,
    relatedPost: [],
    postSelected: false,

    // comment session
    comments: [],
    comment_complete: null,
    comment_votes: [],
    comment_count: [],
    createComment: '',

    // vote session
    postVotes: null,
    userVote: null,

    // draft session
    draft: null,
    draftThumbnail: false

}
const postReducer = (state = initialState, action) => {
    switch (action.type) {
        // data session
        case ALL_POSTS:
            let newArray = action.payload;
            return {
                ...state,
                allposts: newArray
            }
        case ALL_POSTS_FETCHED:
            return {
                ...state,
                posts_fetched: action.payload
            }
        case TRENDING_POSTS:
            return {
                ...state,
                trending: action.payload
            }
        case TREND_FETCHED:
            return {
                ...state,
                trendFetched: action.payload
            }
        case DISCUSS:
            let posts = state.discuss.concat(action.payload)
            return {
                ...state,
                discuss: posts
            }
        case DISCUSS_COMMENT_COUNT:
            let discussCommentCount = state.discussPostCommentCount.concat(action.payload)
            return {
                ...state,
                discussPostCommentCount: discussCommentCount
            }
        case DISCUSS_FETCHED:
            return {
                ...state,
                discuss_fetched: action.payload
            }
        case CLEAR_DISCUSS:
            return {
                ...state,
                discuss: []
            }
        case CLEAR_COMMENT_COUNT:
            return {
                ...state,
                discussPostCommentCount: []
            }
        case CLEAR_ALL_DATA:
            return {
                ...state,
                alldata: []
            }

        // post session
        case GET_POST:
            return {
                ...state,
                selectedPost: action.payload
            }
        case RELATED_POST:
            return {
                ...state,
                relatedPost: action.payload
            }
        case POST_SELECTED:
            return {
                ...state,
                postSelected: action.payload
            }
        case GET_COMMENT_COUNT:
            return {
                ...state,
                postCommentCount: action.payload
            }

        case USER_PROFILE_POSTS:
            return {
                ...state,
                userProfilePosts: action.payload
            }
        // comment session
        case COMMENTS:
            return {
                ...state,
                comments: action.payload
            }
        case CREATE_COMMENT:
            return {
                ...state,
                createComment: action.payload
            }
        case ADD_COMMENTS:
            let updated_array = [action.payload].concat(state.comments)
            return {
                ...state,
                comments: updated_array
            }
        case CLEAR_COMMENTS:
            let comment_array = []
            return {
                ...state,
                comments: comment_array
            }
        case COMMENT_COUNT:
            return {
                ...state,
                comment_count: action.payload
            }


        // vote
        case CREATE_VOTE:
            return {
                ...state,
                userVote: action.payload
            }

        // draft sesssion
        case DRAFT_FETCHED:
            let draft;
            state.userProfilePosts.map(post => {
                return (post.post._id === action.payload
                    ? draft = post.post
                    : null)
            })
            return {
                ...state,
                draft: draft
            }
        case DRAFT_THUMBNAIL:
            return {
                ...state,
                draftThumbnail: action.payload
            }
        case CLEAR_DRAFT:
            return {
                ...state,
                draft: null
            }
        case DRAFT_STATUS:
            return {
                ...state,
                draftStatus: !state.draftStatus
            }
        case DRAFT_REMOVAL:
            let draftArray = state.userProfilePosts.filter(post => post.post._id !== action.payload)
            return {
                ...state,
                userProfilePosts: draftArray
            }


        // typeahead
        case TYPEAHEAD:
            return {
                ...state,
                typeahead: action.payload
            }
        case TAGS:
            return {
                ...state,
                items: action.payload
            }

        // post content section
        case POST_CONTENT:
            return {
                ...state,
                postContent: action.payload
            }

        // tags session
        case TAG_CREATION:
            return {
                ...state,
                items: state.items.concat(action.payload)
            }
        case TAG_REMOVAL:
            return {
                ...state,
                items: state.items.filter(item => item !== action.payload)
            }
        case CLEAR_TAGS:
            let array = []
            return {
                ...state,
                items: array
            }
        default: return state;
    }
}
export default postReducer;