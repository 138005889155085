import axios from '../../axios';
import {
    LOGIN, PROFILE_FETCHED, PHOTO_FETCHED, ALL_POSTS_FETCHED, POINTS
} from './types'

// login
export const login = (data, callback) => async dispatch => {
    try {
        const response = await axios.post('/auth/login', data);
        if (response.status === 200) {
            const { token, user } = response.data;
            localStorage.setItem('loginToken', token);
            let loggedUser = JSON.stringify(user)
            dispatch({ type: POINTS, payload: user.points })
            localStorage.setItem('user', loggedUser);
            dispatch({ type: LOGIN, payload: user })
            callback(null);
        }
    } catch (error) {
        callback(error.response.data.message)
    }
};

// Signup
export const register = (data, callback) => async dispatch => {
    try {
        const response = await axios.post('/auth/register', data);
        if (response.status === 200) {
            const { token, user } = response.data;
            localStorage.setItem('loginToken', token);
            let loggedUser = JSON.stringify(user)
            localStorage.setItem('user', loggedUser);
            dispatch({ type: LOGIN, payload: user });
            callback(null);
        }
    } catch (error) {
        console.log(error);
        callback(error)
    }
};

// reset password
export const resetPassword = (data) => async dispatch => {
    try {
        axios.post('/auth/forgot-password', data);
    } catch (error) {
        console.log(error)

    }
};

export const logout = (data) => async dispatch => {
    try {
        dispatch({ type: LOGIN, payload: null })
        dispatch({ type: POINTS, payload: 0 })
        dispatch({ type: ALL_POSTS_FETCHED, payload: false })
        dispatch({ type: PROFILE_FETCHED, payload: false })
        dispatch({ type: PHOTO_FETCHED, payload: false })
    } catch (error) {

    }
}
