import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Card } from '../shared/feeds/post-cards/postCard';
import { TopPeople } from '../shared/feeds/top-people/topPeople';
import { getAllProfiles } from '../../store/actions/users.actions';
import { FeaturedLoader, UsersLoader } from '../shared/contentSkeletons/skeleton';
import { getAllPosts, loader, trending, discussloader } from '../../store/actions/post.actions';
import Discussions from '../shared/feeds/discussion/discussion'
import Footer from '../shared/footer'
import './home.css';

class discuss extends Component {
    componentDidMount() {
        document.title = 'Home | FotoGramam';
        window.scrollTo(0, 0);
        this.props.getAllPosts();
        this.props.discussloader();
        this.timer = setTimeout(() => {
            this.props.trending(this.props.user);
        }, 1000)
        this.props.getAllProfiles();
    }
    redirectHandler = (id) => {
        this.props.history.push('/posts/' + id)
    }
    profileRedirectHandler = (id) => {
        this.props.history.push("/profile/" + id)
    }
    componentWillUnmount() {
        clearTimeout(this.timer)
    }
    render() {
        const { featured, featuredAuth, usersFetched, users } = this.props;
        let card = (
            <>
                {
                    featuredAuth
                        ? <>
                            {
                                featured.map((card, i) => {
                                    return (
                                        <Card key={i} data={card} handler={this.redirectHandler} />
                                    )
                                })
                            }
                        </>
                        : <FeaturedLoader />
                }
            </>

        )
        let topPeople = (
            <>
                {
                    usersFetched
                        ? <TopPeople people={users} handler={this.profileRedirectHandler} />
                        : <UsersLoader />
                }
            </>
        )

        return (
            <div style={{ minHeight: "100vh" }}>
                <div>
                    <div>
                        <div className="container d-flex p-lg-5 py-4 featured-cards">
                            {card}
                        </div>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                        {topPeople}
                    </div>
                    <Discussions />
                </div>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        alldata: state.postReducer.allposts,
        featured: state.postReducer.trending,
        featuredAuth: state.postReducer.trendFetched,
        discussAuth: state.postReducer.discuss_fetched,
        postAuth: state.postReducer.posts_fetched,
        usersFetched: state.userReducer.usersFetched,
        user: state.authReducer.currentUser && state.authReducer.currentUser._id,
        users: state.userReducer.usersData,
        comments: state.postReducer.comment_count
    }
};

const mapDispatchToProps = {
    getAllPosts,
    getAllProfiles,
    loader,
    trending,
    discussloader
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(discuss));