import { GET_NOTIFICATIONS, NEW_NOTIFICATION, ADD_NOTIFICATION } from "../actions/types";

const initialState = {
    notifications: [],
    new_notification: false
}
const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            }
        case ADD_NOTIFICATION:
            let newArray = [action.payload].concat(state.notifications)
            return {
                ...state,
                notifications: newArray
            }
        case NEW_NOTIFICATION:
            return {
                ...state,
                new_notification: action.payload
            }
        default:
            return state
    }
}
export default notificationReducer;