import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
// import axios from 'axios';
import { store } from './store/store'
import * as serviceWorker from './serviceWorker';

// server Url
// if (process.env.NODE_ENV === "development") {
//     axios.defaults.baseURL = 'http://localhost:4000'
// }
// else {
//     axios.defaults.baseURL = 'https://api.fotogramam.com/'
// }

ReactDOM.render(
    <Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
