import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { lastUpdatedTime } from '../../../common/timeAgo/timeAgo'
import { PostLoader } from '../../contentSkeletons/skeleton'
import './discussion.css'
class Discuss extends Component {
    _isMounted = false;
    componentDidMount() {
        this._isMounted = true;
    }
    redirectHandler = (id) => {
        this.props.history.push('/posts/' + id)
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const { posts, count } = this.props
        return (
            <div>
                {posts && this._isMounted
                    ? <>
                        {posts.map((post, i) => {
                            return (
                                <div key={i} className="post my-4 p-4" style={{ backgroundColor: "white", cursor: 'pointer' }} onClick={() => this.redirectHandler(post.post._id)}>
                                    <div className="d-xl-flex d-lg-flex d-none d-lg-block d-xl-block d-sm-none d-md-none">
                                        <div className="pr-3">
                                            <button className={["btn-vote-post", post.userVoted ? 'active' : 'border'].join(' ')}>
                                                <img className="vote-icon" src={post.userVoted ? require('../../../../assets/feed_icons/voteActive.svg') : require('../../../../assets/photo-icons/vote.svg')} alt="vote" />
                                                <div className="vote-count">{post.post.upvote_count}</div>
                                            </button>
                                        </div>
                                        <div className="d-flex flex-column ml-2 w-100">
                                            <div className="d-flex w-100 border-bottom">
                                                <div className="pr-2 pb-2 w-100">
                                                    <div className="text-left post-title">
                                                        {post.post.title}
                                                    </div>
                                                    <div className="text-left pb-3 post-desc">
                                                        {post.post.description}
                                                    </div>
                                                </div>
                                                <div className="d-flex ml-auto post-profile">
                                                    {post.post.created_by.u_userName}
                                                    <img className="post-created" src={post.post.created_by.thumbnail ? post.post.created_by.thumbnail : require('../../../../assets/feed_icons/profile.svg')} alt="profile" />
                                                </div>
                                            </div>
                                            <div className="pt-3 d-flex">
                                                <div className="d-flex">
                                                    <button className="btn d-flex align-items-center border mr-3">
                                                        <img className="mr-1 post-comment" src={require('../../../../assets/feed_icons/comment.svg')} alt="vote" />
                                                        <div className="comment-count" >{count.map(commentCount => {
                                                            if (post.post._id === commentCount.post)
                                                                return commentCount.count
                                                        })}</div>
                                                    </button>
                                                    <button className="btn border text-capitalize btn-post-tag">{post.post.tag}</button>
                                                </div>
                                                <div className="ml-auto post-time">{lastUpdatedTime(post.post.created_at)}</div>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="d-flex flex-column d-none d-lg-none d-xl-none">

                                        <div className="d-flex border-bottom">
                                            <div className={["mr-2 d-flex flex-column align-items-center p-2 btn-vote-post", post.userVoted ? 'active' : 'border'].join(' ')}>
                                                <img className="vote-icon" src={post.userVoted ? require('../../../../assets/feed_icons/voteActive.svg') : require('../../../../assets/photo-icons/vote.svg')} alt="vote" />
                                                <div className="vote-count">{post.post.upvote_count}</div>
                                            </div>
                                            <div className="d-flex w-100 ">
                                                <div className="pr-2 pb-2 w-100">
                                                    <div className="text-left post-title">
                                                        {post.post.title}
                                                    </div>
                                                    <div className="text-left pb-3 post-desc">
                                                        {post.post.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="d-flex align-items-center w-100 pt-3">
                                            <div className="d-flex align-items-center mr-3">
                                                <img className="post-created mr-2" src={post.post.created_by.thumbnail ? post.post.created_by.thumbnail : require('../../../../assets/feed_icons/profile.svg')} alt="profile" />
                                                <div className="post-profile">
                                                    {post.post.created_by.u_userName}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center border mr-2 p-2 discuss-comment">
                                                <img className="mr-1 post-comment" src={require('../../../../assets/feed_icons/comment.svg')} alt="vote" />
                                                <div className="comment-count" >{count.map(commentCount => {
                                                    if (post.post._id === commentCount.post)
                                                        return commentCount.count
                                                })}</div>
                                            </div>
                                            <div className="border discuss-comment mr-2 d-flex align-items-center">
                                                <div className="text-capitalize btn-post-tag p-2">{post.post.tag}</div>
                                            </div>
                                            <div className="post-time">{lastUpdatedTime(post.post.created_at)}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                    :
                    <PostLoader />
                }
            </div>
        );
    }
}


export default withRouter(Discuss);
