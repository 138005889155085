import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { myInput, myCheck } from '../Field';
import { agreed, passwordsMustMatch, userValidation, passwordLength } from '../validation/validate';
import { register } from '../../store/actions/auth.actions'
import asyncValidate from '../validation/asyncValidate';
import '../../styles.css';

class signup extends Component {
    state = {
        error: '',
        disabled: false,
        input: ''
    }
    submitHandler = values => {
        this.setState({ disabled: true })
        this
            .props
            .register(values, (err) => {
                if (err) {
                    this.setState({ error: err, disabled: false })
                } else if (!err) {
                    this
                        .props
                        .history
                        .push("/");
                }
            });

    }
    componentDidMount() {
        document.title = 'Signup | Fotogramam';
        if (localStorage.getItem('registerToken') || localStorage.getItem('loginToken')) {

            this
                .props
                .history
                .push("/");
        }
    }
    onChangeHandler = (event) => {
        this.setState({ input: event.target.value })
    }
    render() {
        let error = (
            <div
                style={{
                    backgroundColor: "tomato",
                    color: "white"
                }}>{this.state.error}</div>
        );
        const { handleSubmit } = this.props;
        return (
            <main>
                {error}
                <section className="min-vh-100 d-flex align-items-center my-5">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-8 col-md-6 col-lg-6 offset-md-1 order-md-2">
                                <img
                                    src={require('../../assets/svg/signup.svg')}
                                    alt="..."
                                    className="img-fluid" />
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 order-md-1">
                                <div>
                                    <div className="mb-5 mt-3 text-center">
                                        <h2 className="font-weight-normal">Join FotoGramam</h2>
                                        <p className="text-gray mb-0">Become part of a beautiful community around.</p>
                                    </div>
                                    <span className="clearfix"></span>
                                    <form onSubmit={handleSubmit(this.submitHandler)}>
                                        <Field
                                            name="u_fullName"
                                            icon="user-tie"
                                            component={myInput}
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter fullname"
                                            required="yes"
                                        />
                                        <Field
                                            name="u_userName"
                                            icon="user"
                                            component={myInput}
                                            className="form-control"
                                            type="text"
                                            onChange={this.onChangeHandler}
                                            placeholder="Enter username"
                                            required="yes"
                                            validate={userValidation}
                                        />
                                        <Field
                                            name="u_email"
                                            icon="envelope"
                                            component={myInput}
                                            className="form-control"
                                            type="email"
                                            id="input-email"
                                            placeholder="Enter email"
                                            required="yes" />
                                        <Field
                                            name="u_password"
                                            component={myInput}
                                            icon="unlock-alt"
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            required="yes"
                                            validate={[passwordsMustMatch, passwordLength]} />
                                        <Field
                                            name="confirm-password"
                                            component={myInput}
                                            icon="unlock-alt"
                                            type="password"
                                            className="form-control"
                                            placeholder="ConfirmPassword"
                                            required="yes"
                                            validate={passwordsMustMatch} />
                                        <div className="my-4">
                                            <div className="form-check round-check mb-3">
                                                <label className="form-check-label">
                                                    <Field
                                                        name="checked"
                                                        component={myCheck}
                                                        id="input-password-confirm"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        validate={agreed} />
                                                    <span className="form-check-sign"></span>
                                                    I agree to the
                                                    <a href="./legal.html">&nbsp; terms and conditions</a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="btn btn-block btn-primary animate-up-2"
                                                onSubmit={handleSubmit(this.submitHandler)}
                                                disabled={this.props.invalid || this.state.disabled}>Create account</button>
                                        </div>
                                    </form>
                                    <div className="mt-4 text-center">
                                        <small>Already have an account?</small>
                                        <NavLink to="/auth/login" className="small font-weight-bold">&nbsp; Sign in</NavLink>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
const mapStateToProps = state => {
    return { token: state.authReducer.token }
};
const mapDispatchToProps = {
    register
};

signup = reduxForm({
    form: 'signup',
    asyncValidate,
    asyncBlurFields: ['u_userName']
})(signup);
export default connect(mapStateToProps, mapDispatchToProps)(signup);