import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { RelatedLoader } from '../contentSkeletons/skeleton';
import { lastUpdatedTime } from '../../common/timeAgo/timeAgo'
import './related.css';


class Related extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    handleClickRight = () => {
        if (this.myRef.current) { this.myRef.current.scrollLeft += 400 }
    };
    handleClickLeft = () => {
        if (this.myRef.current) { this.myRef.current.scrollLeft -= 400 }
    };
    render() {
        const { related, handler } = this.props
        return (
            related && related.length !== 0 ?
                <div className="related-contents" >
                    <div className="related-heading">Related Articles</div>
                    <div className="d-flex justify-content-between">

                        <button className="btn" onClick={this.handleClickLeft}>
                            <img src={require('../../../assets/common/scroll-left.svg')} alt="scroll-left" />
                        </button>

                        <div className="d-flex related" ref={this.myRef} id="related" style={{ overflowX: 'scroll', scrollBehavior: 'smooth' }}>
                            {
                                related.map((post, i) => {
                                    return (
                                        <div className="card mx-4 card-shadow related-content" key={i} onClick={() => handler(post.post._id)}>
                                            <img className="card-img-top related-img" src={post.post.thumbnail ? post.post.thumbnail : "https://www.hawtcelebs.com/wp-content/plugins/special-recent-posts-pro/images/no-thumb.png"} alt="Card images cap" />
                                            <div className="card-body">
                                                <div className="card-title text-left related-card-title">{post.post.title}</div>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <img style={{ height: '32px', width: '32px', borderRadius: '20px', objectFit: 'cover', marginRight: "9px" }} src={post.post.created_by.thumbnail ? post.post.created_by.thumbnail : require('../../../assets/feed_icons/profile.svg')} alt="profile" />
                                                        <div className="d-flex flex-column">
                                                            <div className="card-user-name">{post.post.created_by.u_userName}</div>
                                                            <div className="card-post-created">{lastUpdatedTime(post.post.created_by.created_at)}</div>
                                                        </div>
                                                    </div>
                                                    <button className={["btn ml-auto d-flex flex-column align-items-center card-vote-btn ", post.vote ? 'active' : 'border'].join(' ')}>
                                                        <img style={{ height: "14px", width: "11px" }} src={post.vote ? require('../../../assets/common/active-up.svg') : require('../../../assets/photo-icons/vote.svg')} alt="vote" />
                                                        <div className="card-vote">{post.post.upvote_count}</div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <button className="btn" onClick={this.handleClickRight}>
                            <img src={require('../../../assets/common/scroll-right.svg')} alt="scroll-right" />
                        </button>
                    </div>
                </div>
                : null
        );
    }
}


export default withRouter(Related);