import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadProfile } from '../../../store/actions/profile.action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../viewImage/index.css';
class uploadModal extends Component {
    state = {
        photo: '',
        content: '',
        image: '',
        uploaded: false,
        disabled: true
    }
    componentDidMount(){
        this.setState({photo : ''})
    }
    onChangeHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            this.setState({ photo: event.target.files[0] })
            reader.onload = (e) => {
                this.setState({ image: e.target.result, uploaded: true });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    onUploadHandler = () => {
        let file = this.state.photo;
        this.props.uploadProfile(file,(err)=>
        {
            if(!err){
                this.props.history.push("/profile/"+this.props.user._id);
            }
        })
    }
    render() {
        return (
            <div>
                <div className="modal fade upload-modal" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document" style={{ maxWidth: "73.75rem" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="btn" data-dismiss="modal" aria-label="Close" onClick={this.statusHandler}>
                                    <FontAwesomeIcon icon="arrow-left" />
                                </div>
                                <div><strong>UPLOAD PHOTO</strong></div>
                                <div className="btn" data-dismiss="modal" aria-label="Close" onClick={this.statusHandler}>
                                    <FontAwesomeIcon icon="times" />
                                </div>
                            </div>
                            <div className="modal-body ">
                                <div className="ml-3 my-3" style={{ height: "auto" }}>
                                    <div style={{ width: "300px" }}>
                                        <div className="border">
                                            <input type="file"
                                                onChange={this.onChangeHandler}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {this.state.uploaded
                                            ?
                                            <div className="d-flex flex-column align-items-center p-2">
                                                <img
                                                    className="bg-white border"
                                                    src={this.state.image} alt="upload"
                                                    style={{
                                                        width: "200px",
                                                        height: "200px",
                                                        objectFit: 'cover'
                                                    }} />
                                                <button className="btn btn-create mt-2" onClick={this.onUploadHandler}>UPLOAD</button>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user : state.authReducer.currentUser,
        auth: state.userReducer.profile_fetched
    }
};

const mapDispatchToProps = {
    uploadProfile
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(uploadModal));
