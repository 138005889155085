export const userValidation = value => /^[a-z0-9_-]{3,16}$/gim.test(value)
    ? undefined
    : 'Sorry ,only letters (a-z), numbers(0-9) and periods(_-) are allowed !'
export const emailValidation = value => /^(([^<>()[]\.,;:s@"]+(.[^<>()[]\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/igm.test(value)
    ? 'Invalid email address'
    : undefined
export const requiredEmail = value => value
    ? undefined
    : "Enter a valid email !"
export const requiredPassword = value => value
    ? undefined
    : "Enter a valid password !"
// eslint-disable-next-line no-new-wrappers
export const passwordLength = value => new String(value).length >= 6
    ? null
    : 'Password atleast 6 characters !';
export const agreed = value => value
    ? null
    : ' ';
export const publishValidate = value => value
    ? null
    : ' ';
export const passwordsMustMatch = (value, allValues) => value !== allValues.u_password
    ? 'Passwords do not match !'
    : undefined
export const required = value => value !== null
    ? true
    : false
