import React from 'react';
import { lastUpdatedTime } from '../../../common/timeAgo/timeAgo'
import './postCard.css';

export const Card = (props) => {
    const redirect = (id) => {
        props.handler(id)
    }
    return (
        <div className="card mx-4 card-shadow feature-card" onClick={() => redirect(props.data.post.post._id)}>
            <img className="card-img-top feature-card-img" src={props.data.post.post.thumbnail} alt="Card images cap" />
            <div className="card-body">
                <div className="card-title text-left post-card-title">{props.data.post.post.title}</div>
                <div className="d-flex align-items-center">
                    <div className="d-flex">
                        <img style={{ height: '32px', width: '32px', borderRadius: '20px', objectFit: 'cover', marginRight: "9px" }} src={props.data.post.user.thumbnail ? props.data.post.user.thumbnail : "https://www.searchpng.com/wp-content/uploads/2019/02/Deafult-Profile-Pitcher.png"} alt="profile" />
                        <div className="d-flex flex-column">
                            <div className="card-user-name">{props.data.post.user.u_userName}</div>
                            <div className="card-post-created">{lastUpdatedTime(props.data.post.user.created_at)}</div>
                        </div>
                    </div>
                    <button className={["btn ml-auto d-flex flex-column align-items-center card-vote-btn ", props.data.userVoted ? 'active' : 'border'].join(' ')}>
                        <img style={{ height: "14px", width: "11px" }} src={props.data.userVoted ? require('../../../../assets/feed_icons/voteActive.svg') : require('../../../../assets/photo-icons/vote.svg')} alt="vote" />
                        <div className="card-vote">{props.data.post.post.upvote_count}</div>
                    </button>
                </div>
            </div>
        </div>
    );
}