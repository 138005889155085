import axios from '../../axios';
import {
    PROFILE_FETCHED, TAG_CREATION, TAG_REMOVAL, CLEAR_TAGS,
    GET_POST, DRAFT_REMOVAL, DRAFT_FETCHED, DRAFT_STATUS, POST_SELECTED, COMMENTS,
    ADD_COMMENTS, CREATE_VOTE, POST_VOTES, USER_VOTE, CLEAR_ALL_DATA, POST_THUMBNAIL,
    CLEAR_THUMBNAIL, TYPEAHEAD, TAGS, POST_CONTENT, DISCUSS, DISCUSS_FETCHED,
    ALL_POSTS_FETCHED, ALL_POSTS, TRENDING_POSTS, TREND_FETCHED, CLEAR_DISCUSS,
    CREATE_COMMENT, RELATED_POST, GET_COMMENT_COUNT, USER_PROFILE, POINTS, CLEAR_DRAFT, DRAFT_THUMBNAIL, USER_PROFILE_POSTS, DISCUSS_COMMENT_COUNT, CLEAR_COMMENT_COUNT,
} from './types';

// PAGE loader session
export const loader = (callback) => async dispatch => {
    try {
        dispatch({ type: CLEAR_ALL_DATA })
        dispatch({ type: ALL_POSTS_FETCHED, payload: false })
        callback(null);
    } catch (error) { }
}
// discuss loader
export const discussloader = (callback) => async dispatch => {
    try {
        dispatch({ type: DISCUSS_FETCHED, payload: false })
        dispatch({ type: CLEAR_DISCUSS });
        dispatch({type:CLEAR_COMMENT_COUNT})
    } catch (error) {

    }
}

// profile loading session
export const getPosts = (data) => async dispatch => {
    try {
        dispatch({ type: PROFILE_FETCHED, payload: false })
        let response = await axios.post('/post/' + data.id, data);
        if (response.status === 200) {
            dispatch({ type: USER_PROFILE, payload: response.data.user })
            dispatch({ type: USER_PROFILE_POSTS, payload: response.data.posts })
            dispatch({ type: PROFILE_FETCHED, payload: true })
        }
    } catch (error) { }
}
export const relatedContents = (data) => async dispatch => {
    try {
        let response = await axios.post('/post/related/' + data.tag, data)
        if (response.status === 200) {
            dispatch({ type: RELATED_POST, payload: response.data.related })
        }
    } catch (error) {

    }
}
// trending
export const trending = (data, callback) => async dispatch => {
    try {
        let response = await axios.post("/post/posts/trending", { user: data });
        if (response.status === 200) {
            dispatch({ type: TRENDING_POSTS, payload: response.data.posts });
            dispatch({ type: TREND_FETCHED, payload: true })
            callback(null);
        }
    } catch (error) {

    }
}

// POST creation and updation session

export const createPost = (data, callback) => async dispatch => {
    try {
        const formData = new FormData()
        if (data.thumbnail !== null) {
            let file = data.thumbnail;
            formData.append('thumbnail', file);
        }
        formData.append('data', JSON.stringify(data));
        const response = await axios.post('/post/create', formData);
        if (response.status === 200) {
            dispatch({ type: POINTS, payload: response.data.points })
            callback(null);
        }
    } catch (error) {
        console.log(error);
    }
}

// upload thumbnail
export const thumbnailUpload = (data, callback) => async dispatch => {
    try {
        dispatch({ type: POST_THUMBNAIL, payload: data })
    } catch (error) {

    }

}

export const publish = (data, callback) => async dispatch => {
    try {
        const response = await axios.post('/post/publish', data);
        if (response.status === 200) {
            dispatch({ type: CLEAR_TAGS });
            dispatch({ type: CLEAR_THUMBNAIL });
            callback(null)
        }
    } catch (error) {
        callback(error.response.data.message);
    }
}

// POST get session

export const getPost = (data, callback) => async dispatch => {
    try {
        dispatch({ type: POST_SELECTED, payload: false });
        dispatch({ type: USER_VOTE, payload: null });
        dispatch({ type: POST_VOTES, payload: null });
        dispatch({ type: GET_POST, payload: null });
        dispatch({ type: COMMENTS, payload: [] });
        let response = await axios.post('/post/get-post/' + data.id, data);
        if (response.status === 200) {
            dispatch({ type: GET_POST, payload: response.data.post });
            dispatch({ type: CREATE_VOTE, payload: response.data.userVote })
            dispatch({ type: COMMENTS, payload: response.data.comments });
            dispatch({ type: GET_COMMENT_COUNT, payload: response.data.commentCount })
            dispatch({ type: POST_SELECTED, payload: true });
            callback(null);
        }
    } catch (error) {
        callback(error);
    }
}

export const getAllPosts = () => async dispatch => {
    try {
        let response = await axios.get('/post/posts/all');
        if (response.status === 200) {
            dispatch({ type: ALL_POSTS, payload: response.data.posts })
            dispatch({ type: ALL_POSTS_FETCHED, payload: true })
        }
    } catch (error) {

    }
}

export const discuss = (data, callback) => async dispatch => {
    try {
        let response = await axios.post('/post/discuss', data);
        if (response.status === 200) {
            dispatch({ type: DISCUSS, payload: response.data.posts });
            dispatch({ type: DISCUSS_COMMENT_COUNT, payload: response.data.commentCount })
            dispatch({ type: DISCUSS_FETCHED, payload: true })
            callback(null)
        }
        else {
            callback(response.statusText)
        }
    } catch (error) {

    }
}

// POST draft session

export const fetchDraft = (id, callback) => async dispatch => {
    try {
        dispatch({ type: DRAFT_FETCHED, payload: id })
        dispatch({ type: DRAFT_STATUS })
        callback(null)
    } catch (error) {
        console.log(error)
    }
}

export const clearDraft = () => async dispatch => {
    try {
        dispatch({ type: CLEAR_DRAFT })
    } catch (error) {

    }
}

export const draftStatus = () => async dispatch => {
    try {
        dispatch({ type: DRAFT_STATUS });
        dispatch({ type: CLEAR_THUMBNAIL });
        dispatch({ type: CLEAR_TAGS });
    } catch (error) { }
}

export const updateDraft = (data, callback) => async dispatch => {
    try {
        const formData = new FormData()
        if (!data.draft_thumbnail) {
            formData.append('thumbnail', data.thumbnail)
        }
        formData.append('data', JSON.stringify(data));
        let response = await axios.post("/post/draft/update", formData);
        if (response.status === 200) {
            dispatch({ type: POINTS, payload: response.data.points })
            callback(null);
        }
    } catch (error) { }
}
export const draftThumbnail = (data) => async dispatch => {
    try {
        dispatch({ type: DRAFT_THUMBNAIL, payload: data })
    } catch (error) {

    }
}
export const removeDraft = (data, callback) => async dispatch => {
    try {
        let response = await axios.post("/post/draft/" + data);
        if (response.status === 200) {
            dispatch({ type: DRAFT_REMOVAL, payload: response.data.id })
            callback(null);
        }
    } catch (error) {
        callback(error);
    }
}

// POST typeahead section

export const typeAhead = (data, callback) => async dispatch => {
    try {
        dispatch({ type: TYPEAHEAD, payload: data })
    } catch (error) {

    }
}


export const getTags = () => async dispatch => {
    try {
        let response = await axios.get("/post/tags");
        if (response.status === 200) {
            dispatch({ type: TAGS, payload: response.data.tags })
        }
    } catch (error) {

    }
}

// POST content section
export const setContent = (data) => async dispatch => {
    try {
        dispatch({ type: POST_CONTENT, payload: data })
    } catch (error) {

    }
}

// POST tags session

export const tagCreation = (data) => dispatch => {
    try {
        dispatch({ type: TAG_CREATION, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const tagRemoval = (data) => async dispatch => {
    try {
        dispatch({ type: TAG_REMOVAL, payload: data })
    } catch (error) {
        console.log(error)
    }
}

//POST comment and comment vote session

export const comments = (data, callback) => async dispatch => {
    try {
        let response = await axios.post("/post/comment/" + data.id, data);
        if (response.status === 200) {
            dispatch({ type: ADD_COMMENTS, payload: response.data });
            dispatch({ type: POINTS, payload: response.data.points })
            callback(null)
        }
    } catch (error) {
        console.log(error)
    }
}


// POST vote session

export const createVote = (data, callback) => async dispatch => {
    try {
        let id = data.id;
        let response = await axios.post("/post/vote/" + id, data);
        if (response.status === 200) {
            if (response.data.post) { dispatch({ type: GET_POST, payload: response.data.post }); }
            if (response.data.userVote !== null) {
                dispatch({ type: CREATE_VOTE, payload: response.data.userVote });
            }
            dispatch({ type: POINTS, payload: response.data.points })
            if (response.data.comments) { dispatch({ type: COMMENTS, payload: response.data.comments }); }
            callback(null);
        } else {
            callback(200)
        }
    } catch (error) { }
}


// create comment
export const createComment = (comment, callback) => async dispatch => {
    try {
        dispatch({ type: CREATE_COMMENT, payload: comment })
    } catch (error) {

    }
}