import React from 'react'
import { lastUpdatedTime } from '../timeAgo/timeAgo'
import ReactHoverObserver from 'react-hover-observer';
import './hoverDiv.css'
export default class hoverDiv extends React.Component {
    state = {
        height: null,
        visible: false,
        loaded: false
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ visible: true }) }, 100)
    }
    photoSelectHandler = (id) => {
        this.props.handler(id);
    }
    onImageLoad = () => {
        this.setState({ loaded: true })
    }
    render() {
        const { photo, commentCount, userVoted, modalHandler } = this.props;       
        return (
            <>
                <ReactHoverObserver>
                    {
                        ({ isHovering }) => (
                            <div
                                to="/profile/post/gallery/photo"
                                id="grid"
                                className="position-relative photo-card"
                                onClick={() => {
                                    this.photoSelectHandler(photo._id);
                                    modalHandler(true);
                                }}
                                data-toggle="modal" data-target=".bd-example-modal-xl">
                                <div className="card-div" style={{ height: photo.height ? photo.height : "400px", backgroundColor: photo.color ? photo.color : "grey" }}>
                                    {
                                        this.state.visible ?
                                            <img className="photo-grid " onLoad={this.onImageLoad} src={photo.img_url ? photo.img_url : photo.color} alt="grid_photos" style={{ height: photo.height }} />
                                            : null
                                    }
                                </div>
                                {
                                    isHovering &&
                                    <div className=" card-div gallery-image ">
                                        <div className="position-absolute bottom-left top-0 d-flex align-items-center text-white">
                                            <img className="hover-img mr-2" src={photo.uploaded_by.thumbnail} alt="profile" />
                                            <div className="profile-text d-flex flex-column">
                                                <div><strong>{photo.uploaded_by.u_userName}</strong></div>
                                                <div><small>{lastUpdatedTime(photo.uploaded_at)}</small></div>
                                            </div>
                                        </div>
                                        <div className="d-flex position-absolute mr-2 text-white bottom-right">
                                            <div className="mr-2 d-flex btn-photo1 align-items-center px-3 py-1">
                                                <img className="count-img1" src={require('../../../assets/photo-icons/comment.svg')} alt="like" />
                                                <div className="ml-2  count-text1">{commentCount}</div>
                                            </div>
                                            <div className={["d-flex flex-column card-comments ml-1 btn-photo2 align-items-center px-3 py-1", userVoted ? 'active' : null].join(' ')}>
                                                <img className="count-img2" src={userVoted ? require('../../../assets/feed_icons/voteActive.svg') : require('../../../assets/photo-icons/vote.svg')} alt="like" />
                                                <div className="count-text2">{photo.upvote_count - (-photo.downvote_count)}</div>

                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        )
                    }
                </ReactHoverObserver>
            </>
        )
    }
}
