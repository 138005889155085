import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import Login from '../../components/login';
import Signup from '../../components/signup';
import UserSection from './pageRoute';
import ForgotPassword from '../../components/login/resetPassword';
import {
    faUser, faUnlockAlt, faArrowLeft, faSearch, faComment,
    faUserCircle, faComments, faEllipsisV, faTimes, faEnvelope,
    faUserTie, faPlus, faCircleNotch, faTimesCircle, faCaretUp,
    faCaretDown, faThumbsUp, faThumbsDown, faChevronUp, faChevronDown,
    faPlusCircle, faPen, faChevronRight, faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
library.add(
    faUser, faUnlockAlt, faArrowLeft, faSearch, faComment, faUserCircle,
    faComments, faEllipsisV, faTimes, faEnvelope, faUserTie, faPlus,
    faCircleNotch, faTimesCircle, faCaretUp, faCaretDown, faThumbsUp,
    faThumbsDown, faChevronUp, faChevronDown, faPlusCircle, faPen,
    faChevronRight, faChevronLeft
)

class home extends Component {

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <Switch>
                        <Route path="/auth/login" component={Login} />
                        <Route path="/auth/signup" component={Signup} />
                        <Route path='/auth/forgot-password' component={ForgotPassword} />
                        <Route path='/' component={UserSection} />
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default home;
