import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { myInput } from '../../Field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { resetPassword } from '../../../store/actions/auth.actions';
import '../../../styles.css';

class ResetPassword extends Component {
    submitHandler = values => {
        this
            .props
            .resetPassword(values);
    }
    componentDidMount() {
        document.title = 'ForgotPassword | Fotogramam';
    }
    render() {
        const { handleSubmit } = this.props;
        return (
            <main>
                <section className="min-vh-100 d-flex align-items-center">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-8 col-md-6 col-lg-6 offset-md-1 order-md-2">
                                <img
                                    src={require('../../../assets/svg/forgot-password.svg')}
                                    alt="..."
                                    className="img-fluid" />
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 order-md-1">
                                <div>
                                    <div className="text-center text-md-center mt-4 mt-md-0 mb-5">
                                        <h2 className="font-weight-normal">Reset password</h2>
                                        <p className="text-gray mb-0">Add e-mail to send a link to reset your password</p>
                                    </div>
                                    <span className="clearfix"></span>
                                    <form onSubmit={handleSubmit(this.submitHandler)}>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <FontAwesomeIcon icon={faUser} />
                                                    </span>
                                                </div>
                                                <Field
                                                    name="u_email"
                                                    component={myInput}
                                                    className="form-control"
                                                    type="email"
                                                    placeholder="Enter email"
                                                    required />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                onSubmit={handleSubmit(this.submitHandler)}
                                                className="btn btn-block btn-primary animate-up-2">Send link</button>
                                        </div>
                                    </form>
                                    <div
                                        className="d-block d-sm-flex justify-content-between align-items-center mt-4">
                                        <span>
                                            <NavLink to="/auth/login" className="small font-weight-bold">Back to login</NavLink>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        );
    }
}
const mapStateToProps = state => {
    return { token: state.authReducer.token }
};
const mapDispatchToProps = {
    resetPassword
};
ResetPassword = reduxForm({ form: 'resetPassword' })(ResetPassword);
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);