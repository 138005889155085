import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { getAllProfiles } from '../../store/actions/users.actions'
import { connect } from 'react-redux';
import CreatePost from '../../components/CreatePost/CreatePost';
import Navbar from '../../components/shared/navbar';
import PrivateRoute from '../../privateRoute';
import Home from '../../components/home';
import Photos from '../../components/Photos';
import Photograph from '../../components/uploadPhoto/uploadPhoto'
import viewpost from '../../components/shared/viewPost/viewPost';
import Profile from '../../components/Profile/profile';
import Notifications from '../../components/shared/Notifications/Notification'
import '../../styles.css';

class profile extends Component {
    componentDidUpdate() {
    }
    render() {
        return (
            <div>
                <Navbar />
                <main>
                    <Switch>
                        <PrivateRoute path="/createPost" component={CreatePost} />
                        <Route path="/uploadPhotos" component={Photograph} />
                        <Route path='/photos' component={Photos} />
                        <Route path="/profile/:id" component={Profile} />
                        <Route path="/posts/:id" component={viewpost} />
                        <Route path='/notifications' component={Notifications} />
                        <Route path="/home" component={Home} />
                        <Redirect to="/home" from="/" />
                    </Switch>
                </main>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return { auth: state.userReducer.profilefetched }
};

const mapDispatchToProps = {
    getAllProfiles
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(profile));