import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createVote } from '../../../store/actions/post.actions';

class vote extends Component {
    state = {
        disabled: false,
        vote: 0,
        upvote: 0,
        downvote: 0
    }
    voteHandler = (vote) => {
        this.setState({ disabled: true })
        this.state.vote !== vote && vote === 1 ? this.setState({ vote: vote, upvote: this.state.upvote + 1 })
            : this.state.vote !== vote && vote === -1 ? this.setState({ vote: vote, upvote: this.state.downvote - 1 })
                : this.setState({ vote: 0, upvote: vote === 1 ? this.state.upvote - 1 : null, downvote: vote === -1 ? this.state.downvote - 1 : null })
        let data = { id: this.props.post._id, vote: vote, post_id: this.props.post._id, type: "post" }
        this.props.createVote(data, (err) => {
            if (!err) {
                this.setState({ disabled: false })
            }
        });
    }
    componentDidMount() {
        if (this.props.status === 'draft') this.setState({ disabled: true });
        if (localStorage.getItem('loginToken')) {
            if (this.props.post.created_by._id === this.props.user._id) {
                this.setState({ disabled: true })
            }
        } else {
            this.setState({ disabled: true })
        }
        this.setState({ upvote: this.props.post.upvote_count, downvote: this.props.post.downvote_count, vote: this.props.userVote })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.userVote !== this.props.userVote) { this.setState({ vote: this.props.userVote }) }
        if (prevProps.post.upvote_count !== this.props.post.upvote_count) { this.setState({ upvote: this.props.post.upvote_count }) }
        if (prevProps.post.downvote_count !== this.props.post.downvote_count) { this.setState({ upvote: this.props.post.downvote_count }) }
    }
    render() {
        const { modalHandler } = this.props;
        return (
            <div className="d-flex flex-row flex-lg-column flex-xl-column align-items-center">
                <button data-toggle='tooltip' data-placement="top" title={localStorage.getItem("loginToken") ? "UPVOTE" : "Please login to vote"}
                    className={["mb-xl-2 mb-lg-2 btn-icon mr-lg-0 mr-xl-0 mr-3", this.state.vote === 1 ? "active" : "border"].join(' ')}
                    onClick={() =>
                        this.props.points > 0
                            ? this.voteHandler(1)
                            : modalHandler(true)
                    }
                    disabled={this.state.disabled}>
                    <img style={{ width: "16px" }} src={this.state.vote === 1 ? require('../../../assets/common/active-up.svg') : require('../../../assets/photo-icons/vote.svg')} alt="vote" />
                    <div className="vote-count" style={{ fontSize: "12px" }}>{this.state.upvote}</div>
                </button>
                <button data-toggle='tooltip' data-placement="top" title={localStorage.getItem("loginToken") ? "DOWNVOTE" : "Please login to vote"}
                    className={[" btn-icon ", this.state.vote === -1 ? "active" : "border"].join(' ')}
                    onClick={() =>
                        this.props.points > 0
                            ? this.voteHandler(-1)
                            : modalHandler(true)
                    }
                    disabled={this.state.disabled}>
                    <div className="vote-count" style={{ fontSize: "12px" }}>{-(this.state.downvote)}</div>
                    <img style={{ width: "16px" }} src={this.state.vote === -1 ? require('../../../assets/common/active-down.svg') : require('../../../assets/photo-icons/vote2.svg')} alt="vote" />

                </button>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        post: state.postReducer.selectedPost,
        userVote: state.postReducer.userVote,
        user: state.authReducer.currentUser,
        points: state.authReducer.points
    }
};

const mapDispatchToProps = {
    createVote
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(vote));
