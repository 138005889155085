import axios from '../../axios'
import { PROFILE_FETCHED, LOGIN } from "./types"

export const profileView = () => async dispatch => {
    try {
        dispatch({ type: PROFILE_FETCHED, payload: false })
    } catch (error) {

    }
}

export const uploadProfile = (file,callback) => async dispatch => {
    try {
        const formData = new FormData()
        formData.append('profile', file);
        let response = await axios.post("/users/profile/upload",formData)
        if (response.status === 200) {
            dispatch({ type: LOGIN, payload: response.data.data })
            callback(null);
        }
    } catch (error) {

    }
}