import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Photos from './photo'
import './photos.css';

class photos extends Component {
    render() {
        return (
            <>
                <Switch>
                    <Route path="/photos/:tag" component={Photos} />
                </Switch>
                <Redirect from='/photos/:tag' to='/photos/all' />
            </>
        );
    }
}


export default photos;