import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createComment } from '../../../store/actions/post.actions'
import TextAreaAutoSize from 'react-textarea-autosize'
import PropTypes from 'prop-types';
import './comment.css'
class Comment extends Component {
    state = {
        comment: '',
        visible: false,
        disabled: true,
        text_class: 'text-dark',
        blur: false
    }
    componentDidMount() {
        if (localStorage.getItem('loginToken')) { this.setState({ disabled: false }) }
    }
    onCommentChangeHandler = (e) => {
        this.setState({ comment: e.target.value })
        this.props.createComment(e.target.value);
    }
    render() {
        const { comment, user, handler, thumbnail, type, rows, points, modalHandler } = this.props;
        const isDisabled = () => {
            return !comment
        };
        return (
            <div className="comment">
                {
                    type === 'photo' ?
                        <textarea
                            onClick={() => points < 0 ? modalHandler(true) : null}
                            rows={rows}
                            style={{ padding: "10px 0px 0px 36px", backgroundColor: "white" }}
                            className={["formControl position-relative photo-comment-box", this.state.text_class].join(' ')}
                            placeholder={type === 'post' ? user ? `${user.u_userName} , Comment your thoughts here!` : 'Hai user , Comment your thoughts here!' : null}
                            value={comment}
                            disabled={this.state.disabled}
                            onChange={this.onCommentChangeHandler}
                        />
                        : <TextAreaAutoSize
                            minRows={rows}
                            disabled={this.state.disabled}
                            onClick={() => points < 0 ? modalHandler(true) : null}
                            className={["formControl position-relative post-comment-box", this.state.text_class].join(' ')}
                            placeholder={type === 'post' ? user ? `${user.u_userName} , Comment your thoughts here!` : 'Hai user , Comment your thoughts here!' : null}
                            value={comment}
                            onChange={this.onCommentChangeHandler}
                        />
                }
                <img className={["position-absolute", type === 'photo' ? 'photo-create-thumbnail' : 'post-create-thumbnail', this.state.blur ? 'img-loader' : null].join(' ')} src={thumbnail ? thumbnail : require('../../../assets/feed_icons/profile.svg')} alt="profile" />
                {
                    isDisabled()
                        ? null
                        : <button className={["btn", type === 'photo' ? 'photo-create-button' : 'post-create-button'].join(' ')}
                            onClick={() => {
                                handler(comment);
                                this.setState({ text_class: 'text-loader', disabled: true, blur: true })
                                setTimeout(() => {
                                    this.setState({ text_class: 'text-dark', disabled: false, blur: false })
                                    this.props.createComment('');
                                }, 2000)
                            }}
                            disabled={this.state.disabled}
                        >
                            <strong>
                                COMMENT
                        </strong>
                        </button>

                }
            </div>
        );
    }
}
Comment.protoType = {
    comment: PropTypes.string.isRequired
}
const mapStateToProps = state => {
    return {
        comment: state.postReducer.createComment,
        thumbnail: state.authReducer.currentUser && state.authReducer.currentUser.thumbnail,
        points: state.authReducer.points
    }
};
const mapDispatchToProps = {
    createComment
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Comment));