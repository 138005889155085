import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css'

export const myInput = ({ input, className, type, placeholder, icon, label, required, meta: { touched, error, warning } }) => {
    return (
        <div className="form-group">
            <label className="float-left">
                <b>{label}</b>
            </label>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text border-right-0">
                        <FontAwesomeIcon icon={icon} />
                    </span>
                </div>
                <input {...input} type={type} className={className} placeholder={placeholder} required={required} />
            </div>
            <div style={{
                color: "red",
                float: "left",
                fontSize: "12px"
            }}>
                {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
}



export const myCheck = ({ input, className, type, name, meta: { touched, error, warning } }) => {
    return (
        <React.Fragment>
            <input {...input} type={type} className={className} name={name} /> {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </React.Fragment>
    )
}





export const textArea = ({ input, className, rows, name, columns, label, placeholder, required }) => {
    var inputValue = document.getElementsByClassName("text-value");
    let border = document.getElementsByClassName("text-border");
    if (input.value.length > 0) {
        setTimeout(() => {
            var element = document.getElementById("demo");
            element.classList.add("border-div", "description-text-after");
            element.classList.remove("border-div2", "description-text-before");
        }, 100)
    }
    var onFocus = () => {
        border[0].classList.add("border-div", "description-text-after");
        border[0].classList.remove("border-div2", "description-text-before");
    };
    var onBlur = () => {
        if (!inputValue[0].value) {
            border[0].classList.remove("border-div", "description-text-after");
            border[0].classList.add("border-div2", "description-text-before");
        }
    };
    var onClick = () => {
        border[0].classList.add("border-div", "description-text-after");
        border[0].classList.remove("border-div2", "description-text-before");
    }

    for (var i = 0; i < inputValue.length; i++) {
        inputValue[i].addEventListener('focus', onFocus, false);
        inputValue[i].addEventListener('blur', onBlur, false);
        border[i].addEventListener('click', onClick, false)
    }
    return (
        <div className="form-group col-12 input-border d-flex flex-column mb-4 p-1">
            <div id="demo" className="border-div2 description-text-before text-border">{label}</div>
            <textarea  {...input} style={{ overflow: "hidden", minHeight: "5rem" }} className={["text-value", className].join(' ')} name={name} rows={rows} columns={columns} placeholder={placeholder} required={required} />
        </div>

    );
}



export const postInput = ({ input, className, type, placeholder, label, required, meta: { touched, error, warning } }) => {
    var inputValue = document.getElementsByClassName("input-value");
    let border = document.getElementsByClassName("border-class");

    var onFocus = () => {
        border[0].classList.add("border-div", "title-text-after");
        border[0].classList.remove("border-div2", "title-text-before");
    };
    var onBlur = () => {
        if (!inputValue[0].value) {
            border[0].classList.remove("border-div", "title-text-after");
            border[0].classList.add("border-div2", "title-text-before");
        }
    };
    var onClick = () => {
        border[0].classList.add("border-div", "title-text-after");
        border[0].classList.remove("border-div2", "title-text-before");
    }
    for (var i = 0; i < inputValue.length; i++) {
        if (inputValue[0].value) {
            border[0].classList.add("border-div", "title-text-after");
            border[0].classList.remove("border-div2", "title-text-before");
        }
        inputValue[i].addEventListener('focus', onFocus, false);
        inputValue[i].addEventListener('blur', onBlur, false);
        border[i].addEventListener('click', onClick, false)
    }
    return (
        <div className="form-group col-12 input-border d-flex flex-column mb-4 p-1">
            <div className="border-div2 title-text-before border-class">{label}</div>
            <input {...input} type={type} style={{ padding: "15px 26px" }} className={["input-value", className].join(' ')} placeholder={placeholder} required={required} />
        </div >

    );
}
